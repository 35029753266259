import { accessByPersonalPermission } from 'modules/auth/helpers/accessByPersonalPermission';

export const getAvailableRoutesByPermissions = ({
  routes,
  authenticated,
  userRole,
  hub,
  email,
}) => {
  let availableRoutes = routes;
  if (email) {
    availableRoutes = routes.filter(
      (menuItem) =>
        !menuItem.personalPermission ||
        accessByPersonalPermission(menuItem.personalPermission, email, hub),
    );
  }

  availableRoutes = authenticated
    ? availableRoutes.filter(
        (menuItem) =>
          (!menuItem.hub || menuItem.hub.includes(hub)) &&
          (!menuItem.access || menuItem.access?.includes(userRole)),
      )
    : availableRoutes.filter((menuItem) => !menuItem.isPrivate);

  return availableRoutes.filter((item) => item.visible);
};

export const filterMenuItems = ({ items, routes }) => {
  return items.filter((item) => {
    if (!item.path) {
      return true;
    }
    return routes?.some((route) => {
      const path = item.routePath || item.path;
      if (Array.isArray(route.path)) {
        return route.path.some((p) => p === path);
      }
      return route.path === path;
    });
  });
};

export const getAvailableMenuItems = ({ items, routes }) => {
  let availableItems = filterMenuItems({
    items,
    routes,
  });

  availableItems = availableItems.map((item) => {
    if (item.items) {
      item.items = filterMenuItems({
        items: item.items,
        routes,
      });
    }
    return item;
  });

  return availableItems.filter((item) => {
    if (!item.path) {
      return item.items.length !== 0;
    }

    return true;
  });
};
