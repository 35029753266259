import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PrimaryButton from 'modules/common/PrimaryButton';
import SecondaryButton from 'modules/common/SecondaryButton';
import CustomLink from 'modules/common/CustomLink';

const TableButtonsWithConditionField = ({
  classes,
  singleButtonFieldProps,
  twoButtonsFieldProps,
  conditionLabel,
  condition,
  dataLabel,
  tableItem,
  openDeclineModal,
  openConfirmModal,
  showLabel,
  isHeadRow,
}) => {
  const checkCondition = (tableItem) => {
    return condition.includes(get(tableItem, conditionLabel));
  };

  const getRoute = (item) => {
    return item.type === 'followUp'
      ? singleButtonFieldProps.route[1]
      : singleButtonFieldProps.route[0];
  };

  const getRouteId = (item) => {
    return item.type === 'followUp' ? get(tableItem, 'userId') : get(tableItem, dataLabel);
  };

  if (showLabel && isHeadRow) {
    return <div className={classes.item} />;
  }

  return (
    <div className={classes.item}>
      {condition && checkCondition(tableItem) ? (
        <div className={classes.buttonContainer}>
          {singleButtonFieldProps.route ? (
            <CustomLink to={`${getRoute(tableItem)}/${getRouteId(tableItem)}`}>
              <PrimaryButton
                color={singleButtonFieldProps.color || 'primary'}
                label={singleButtonFieldProps.label}
                variant={singleButtonFieldProps.variant || 'contained'}
                className={`${classes.button} ${classes.button__large} ${classes.button__approve}`}
              />
            </CustomLink>
          ) : (
            <PrimaryButton
              color="secondary"
              label={singleButtonFieldProps.label}
              onClick={(e) => singleButtonFieldProps.onClick(e, get(tableItem, dataLabel))}
              className={`${classes.button} ${classes.button__large}`}
            />
          )}
        </div>
      ) : (
        twoButtonsFieldProps && (
          <div className={classes.buttonsContainer}>
            <SecondaryButton
              variant="contained"
              color="secondary"
              label={twoButtonsFieldProps.leftButtonLabel}
              className={`${classes.button} ${classes.button__small} ${classes.button__decline}`}
              onClick={() => openDeclineModal(tableItem)}
            />
            <PrimaryButton
              color="primary"
              label={twoButtonsFieldProps.rightButtonLabel}
              className={`${classes.button} ${classes.button__large}`}
              onClick={() => openConfirmModal(tableItem)}
            />
          </div>
        )
      )}
    </div>
  );
};

TableButtonsWithConditionField.propTypes = {
  dataLabel: PropTypes.string,
  conditionLabel: PropTypes.string,
  condition: PropTypes.any,
  tableItem: PropTypes.object.isRequired,
  openConfirmModal: PropTypes.func,
  openDeclineModal: PropTypes.func,
  singleButtonFieldProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    route: PropTypes.array,
    color: PropTypes.string,
  }),
  twoButtonsFieldProps: PropTypes.shape({
    leftButtonLabel: PropTypes.string.isRequired,
    rightButtonLabel: PropTypes.string.isRequired,
  }),
  showLabel: PropTypes.bool,
};

export default withStyles(styles)(TableButtonsWithConditionField);
