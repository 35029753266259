import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, matchPath, Redirect } from 'react-router-dom';

import { Routes } from 'constants/routeConstants';
import NotFoundView from '../components/NotFoundView';
import { getAllRoutes } from 'components/App/utils/routes';
import { getIsAuthenticated } from 'modules/auth/selectors';
import { LOGOUT_REASON } from '../../../auth/constants';

const NotFoundContainer = () => {
  const history = useHistory();
  const userAuthenticated = useSelector(getIsAuthenticated);
  const routes = getAllRoutes({});

  const handleRedirectToHome = () => {
    if (!userAuthenticated) {
      history.push(Routes.SIGNIN);
    } else {
      history.push(Routes.HOME);
    }
  };

  let forceRedirect = false;
  if (!userAuthenticated) {
    const { pathname } = history.location;
    const isRoute = routes.some((route) => matchPath(pathname, route));
    if (isRoute) {
      forceRedirect = true;
    }
  }

  const buttonLabel = !userAuthenticated ? 'loginPage' : 'homePage';

  return forceRedirect ? (
    <Redirect to={{ pathname: Routes.SIGNIN, state: { reason: LOGOUT_REASON.AUTH_FAIL } }} />
  ) : (
    <NotFoundView
      onRedirectToHome={handleRedirectToHome}
      userAuthenticated={userAuthenticated}
      buttonLabel={buttonLabel}
    />
  );
};

export default NotFoundContainer;
