export const NOTIFICATION_CATEGORIES = {
  MARKET_HOURS_UPDATES: 'marketHoursUpdates',
  NEW_FEATURES_IN_THE_HUB: 'newFeaturesInTheHub',
  NEW_PAYMENT_OPTIONS: 'newPaymentOptions',
  PROMOTIONS: 'promotions',
  TRADE_IDEAS: 'tradeIdeas',
  COMPANY_NEWS: 'companyNews',
  OPERATIONAL_UPDATES: 'operationalUpdates',
};

export const NOTIFICATION_CLIENT_TYPES = {
  WHOLESALE: 'wholesale',
  PROFESSIONAL: 'professional',
  RETAIL: 'retail',
  ALL: 'all',
};
