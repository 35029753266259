import { handleActions } from 'redux-actions';
import { postJoinWaitlistFail, postJoinWaitlistSuccess } from '../actions';

const defaultState = {
  joinedWaitlist: localStorage.getItem('joinedDay1Waitlist') === 'true' || false,
};

export default handleActions(
  {
    [postJoinWaitlistFail](state) {
      return {
        ...state,
        joinedWaitlist: false,
      };
    },
    [postJoinWaitlistSuccess](state) {
      localStorage.setItem('joinedDay1Waitlist', true);

      return {
        ...state,
      };
    },
  },
  defaultState,
);
