import { all, put, takeEvery } from 'redux-saga/effects';
import { change, initialize, stopSubmit } from 'redux-form';
import { push } from 'modules/reduxNavigation/actions';
import get from 'lodash/get';

import * as actionCreators from '../actions';
import { FORGOT_PASSWORD_FORM, FORGOT_PASSWORD_MODAL } from '../constants';
import * as modalActionCreators from 'modules/common/CustomModal/actions';
import { GLOBAL_PRIME } from '../../../../../constants';
import { setSelectHubOpen } from '../actions';

function* redirectToSignIn() {
  yield put(push('/auth/sign-in'));
}

function* showError(action) {
  const errorId = get(action, 'error.data.index');
  if (['155', '156', '157'].includes(errorId)) {
    yield put(change(FORGOT_PASSWORD_FORM, 'hub', GLOBAL_PRIME));
    yield put(setSelectHubOpen(true));
    return;
  }

  if (errorId === '19') {
    const { message: errorMessage } = get(action, 'error.data', {});
    yield put(
      stopSubmit(FORGOT_PASSWORD_FORM, {
        _error: errorMessage,
      }),
    );
    return;
  }
  yield put(modalActionCreators.toggleModal(FORGOT_PASSWORD_MODAL));
}

function* showModal() {
  yield put(modalActionCreators.toggleModal(FORGOT_PASSWORD_MODAL));
}

function* initializeForm() {
  yield put(initialize(FORGOT_PASSWORD_FORM));
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.redirectToSignIn, redirectToSignIn),
    takeEvery(actionCreators.forgotPasswordFail, showError),
    takeEvery(actionCreators.forgotPasswordSuccess, showModal),
    takeEvery(actionCreators.initializeForm, initializeForm),
  ]);
}
