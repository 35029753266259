import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';
import PrimaryButton from 'modules/common/PrimaryButton';
import image404 from 'static/404page.png';

const NotFoundPage = ({ classes, onRedirectToHome, buttonLabel }) => {
  const { t } = useTranslation('notFoundPage');
  return (
    <>
      <DocumentTitle title={t('title')} />
      <section className={classes.section}>
        <div className={classes.contentContainer}>
          <Typography className={classes.title}> {t('oops')} </Typography>
          <Typography className={classes.description}>{t('neverExisted')}</Typography>
          <div className={classes.buttons}>
            <PrimaryButton
              color="primary"
              label={t(buttonLabel)}
              onClick={onRedirectToHome}
              className={classes.goHomeButton}
              size="large"
            />
          </div>
        </div>
        <img src={image404} alt="404" className={classes.image} />
      </section>
    </>
  );
};

export default withStyles(styles)(NotFoundPage);
