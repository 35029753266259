import React, { useCallback, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import PrimaryButton from '../../../common/PrimaryButton';
import SettingsIcon from '@material-ui/icons/Settings';
import FormSelect from '../../../common/FormSelect';
import { CUSTOM_DATE_RANGE_SELECTOR } from '../../constants';
import {
  DASHBOARD_OVERVIEW_DATA_HEADER_FORM,
  DASHBOARD_STATS_CUSTOMIZATION_MODAL,
} from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../common/CustomModal/actions';
import StatsCustomizationModal from '../StatsCustomizationModal';
import { getDateRangeValuesByCustomType } from '../../utils/dateRange';
import Checkbox from '../../../common/Checkbox';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from 'material-latest';

import { SIZES } from 'components/TP-UI/constants';
import TPDatePicker from 'components/TP-UI/TPDatePicker';

import styles, { styles as componentStyles } from './styles';

const HeaderPanel = ({ classes, handleSubmit, statsCustomizationProps, form }) => {
  const dispatch = useDispatch();
  const [customDateRangeItems, setCustomDateRangeItems] = useState(CUSTOM_DATE_RANGE_SELECTOR);
  const [customCompareDate, setCustomCompareDate] = useState(CUSTOM_DATE_RANGE_SELECTOR);
  const [expanded, setExpanded] = useState(false);

  const { shouldCompare } = useSelector(getFormValues(form));

  const onCustomRangeChange = useCallback(
    (value) => {
      const { dateFrom, dateTo } = getDateRangeValuesByCustomType(value);
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateFrom', dateFrom));
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateTo', dateTo));
      setCustomDateRangeItems([...CUSTOM_DATE_RANGE_SELECTOR]);
    },
    [dispatch],
  );

  const onCompareRangeChange = useCallback(
    (value) => {
      const { dateFrom, dateTo } = getDateRangeValuesByCustomType(value);
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateFromCompare', dateFrom));
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateToCompare', dateTo));
      setCustomCompareDate([...CUSTOM_DATE_RANGE_SELECTOR]);
    },
    [dispatch],
  );

  const onDateChange = useCallback(() => {
    setCustomDateRangeItems([
      { label: 'Custom', value: 'custom', disabled: true },
      ...CUSTOM_DATE_RANGE_SELECTOR,
    ]);
    dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'customDateSelector', 'custom'));
  }, [dispatch]);

  const onCompareDateChange = useCallback(() => {
    setCustomCompareDate([
      { label: 'Custom', value: 'custom', disabled: true },
      ...CUSTOM_DATE_RANGE_SELECTOR,
    ]);
    dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'compareDateSelector', 'custom'));
  }, [dispatch]);

  const toggleStatsCustomizationModal = useCallback(
    () => dispatch(toggleModal(DASHBOARD_STATS_CUSTOMIZATION_MODAL)),
    [dispatch],
  );

  const toggleExpandFilter = useCallback(() => setExpanded(!expanded), [expanded]);

  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container className={classes.root} direction="column">
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item className={classes.customSelector}>
                    <Field
                      onChange={onCustomRangeChange}
                      name="customDateSelector"
                      id="customDateSelector"
                      component={FormSelect}
                      items={customDateRangeItems}
                      multiple={false}
                    />
                  </Grid>
                  <Grid item>
                    <Box sx={componentStyles.dateContainer}>
                      <Field
                        name="dateFrom"
                        id="dateFrom"
                        component={TPDatePicker}
                        onChange={onDateChange}
                        textFieldClassName={[componentStyles.dateTextField]}
                        reservedErrorSpace={false}
                        size={SIZES.SMALL}
                        showStartAdornment={false}
                      />
                    </Box>
                  </Grid>
                  <Grid>
                    <Box mx={1} display="inline-flex" alignItems="center">
                      -
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={componentStyles.dateContainer}>
                      <Field
                        name="dateTo"
                        id="dateTo"
                        component={TPDatePicker}
                        onChange={onDateChange}
                        textFieldClassName={[componentStyles.dateTextField]}
                        reservedErrorSpace={false}
                        size={SIZES.SMALL}
                        showStartAdornment={false}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      color="secondary"
                      label="Update"
                      className={classes.button}
                      size="small"
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <SettingsIcon className={classes.icon} onClick={toggleStatsCustomizationModal} />
                <ExpandIcon className={classes.icon} onClick={toggleExpandFilter} />
              </Grid>
            </Grid>
          </Grid>
          {expanded && (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item className={classes.textBetween}>
                      <Field name="shouldCompare" component={Checkbox} label="Compare to:" />
                    </Grid>
                    <Grid item className={classes.customSelector}>
                      <Field
                        onChange={onCompareRangeChange}
                        name="compareDateSelector"
                        id="compareDateSelector"
                        component={FormSelect}
                        items={customCompareDate}
                        multiple={false}
                        disabled={!shouldCompare}
                      />
                    </Grid>
                    <Grid item>
                      <Box sx={componentStyles.dateContainer}>
                        <Field
                          name="dateFromCompare"
                          id="dateFromCompare"
                          component={TPDatePicker}
                          onChange={onCompareDateChange}
                          textFieldClassName={[componentStyles.dateTextField]}
                          reservedErrorSpace={false}
                          size={SIZES.SMALL}
                          showStartAdornment={false}
                          disabled={!shouldCompare}
                        />
                      </Box>
                    </Grid>
                    <Grid>
                      <Box mx={1} display="inline-flex" alignItems="center">
                        -
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={componentStyles.dateContainer}>
                        <Field
                          name="dateToCompare"
                          id="dateToCompare"
                          component={TPDatePicker}
                          onChange={onCompareDateChange}
                          textFieldClassName={[componentStyles.dateTextField]}
                          reservedErrorSpace={false}
                          size={SIZES.SMALL}
                          showStartAdornment={false}
                          disabled={!shouldCompare}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      <StatsCustomizationModal
        {...statsCustomizationProps}
        toggleModal={toggleStatsCustomizationModal}
      />
    </>
  );
};

const HeaderPanelStyled = withStyles(styles)(HeaderPanel);

export default reduxForm()(HeaderPanelStyled);
