import { put } from 'redux-saga/effects';
import get from 'lodash/get';
import { showNotificationError } from 'modules/notifications/actions';

function* showErrorMessage(action, defaultMessage = 'backend:somethingWrongContactHelp') {
  const message = get(action, 'error.data.message', {
    key: defaultMessage,
  });
  yield put(showNotificationError(message));
}

export default showErrorMessage;
