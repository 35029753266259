export const styles = {
  dateContainer: (theme) => theme.datePickerTable.dateContainer,
  dateTextField: (theme) => theme.datePickerTable.dateTextField,
};

export default () => ({
  root: {
    padding: 10,
  },
  button: {
    height: 32,
    marginLeft: 15,
  },
  icon: {
    color: '#c2c2c5',
    cursor: 'pointer',
  },
  customSelector: {
    marginRight: 15,
  },
  textBetween: {
    margin: '0 6px',
    fontSize: '16px',
  },
  statsCustomization: {},
});
