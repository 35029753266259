export const DATE_MASK_OPTIONS = {
  dateMask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  dateTimeMask: [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /\d/,
    /\d/,
    ' ',
    /\w/,
    /\w/,
  ],
};

export const DATE = {
  dateFormat: 'dd/MM/yyyy',
  dateMask: DATE_MASK_OPTIONS.dateMask,
};
