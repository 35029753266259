import React, { lazy } from 'react';

import { Roles } from 'constants/userRoles';
import { GLOBAL_PRIME, GLOBAL_PRIME_X } from '../../../constants';
import { MODULES_PERMISSION_KEY } from 'constants/permissions';
import { Routes } from 'constants/routeConstants';

import Home from 'modules/home';
import MyAccounts from 'modules/accounts/pages/MyAccounts';
import MfaInput from 'modules/auth/pages/MfaInput';
import MfaSetup from 'modules/auth/pages/MfaSetup';
import MfaRequired from 'modules/auth/pages/MfaRequired';
import ForgotPassword from 'modules/auth/pages/ForgotPassword';
import Migration from 'modules/auth/pages/Migration';
import ForcePasswordChange from 'modules/auth/pages/ForcePasswordChange';
import SignIn from 'modules/auth/pages/SignIn';
import SignUp from 'modules/auth/pages/SignUp';
import ResetPassword from 'modules/auth/pages/ResetPassword';
import Dashboard from 'modules/dashboard';
import OutdatedPassword from 'modules/auth/pages/ForgotPassword/containers/OutdatedPasswordContainer';
import EmailVerification from 'modules/auth/pages/EmailVerification';

import BankTransfer from 'modules/payments/pages/Deposit/containers/BankTransferPaymentContainer';
import DepositMonoovaContainer from 'modules/payments/pages/Deposit/containers/DepositMonoovaContainer';

import DownloadsContainer from 'modules/tradingTools/containers/DownloadsContainer';
import DownloadMobileContainer from 'modules/tradingTools/containers/DownloadMobileContainer';
import ResearchContainer from 'modules/tradingTools/containers/ResearchContainer';
import TechnicalAnalysisContainer from 'modules/tradingTools/containers/TechnicalAnalysisContainer';
import ImpactNewsContainer from 'modules/tradingTools/containers/ImpactNewsContainer';
import CorrelatingContainer from 'modules/tradingTools/containers/CorrelatingContainer';
import MarketReportContainer from 'modules/tradingTools/containers/MarketReportContainer';
import PerformanceStatisticsContainer from 'modules/tradingTools/containers/PerformanceStatisticsContainer';
import VolatilityAnalysisContainer from 'modules/tradingTools/containers/VolatilityAnalysisContainer';
import NewsSentimentContainer from 'modules/tradingTools/containers/NewsSentimentContainer';
import MessagingAndAlertsContainer from 'modules/tradingTools/containers/MessagingAndAlertsContainer';
import EducationContainer from 'modules/tradingTools/containers/EducationContainer';

const ContactUs = lazy(() => import('modules/contactUs'));
const Discover = lazy(() => import('modules/discover/components/DiscoverPage'));
const DiscoverDashboard = lazy(() => import('modules/discover/pages/Dashboard'));
const MyFeeds = lazy(() => import('modules/discover/pages/MyFeeds'));
const MyFeedDetails = lazy(() => import('modules/discover/pages/MyFeedDetails'));
const Sentiment = lazy(() => import('modules/discover/pages/Sentiment'));
const NewsSearch = lazy(() => import('modules/discover/pages/Search/containers'));
const MigrateMyAccount = lazy(() => import('modules/migrateMyAccount'));
const MyPerformance = lazy(() => import('modules/myPerformance'));
const UserPerformance = lazy(() => import('modules/myPerformance/containers/userPerformance'));
const Tools = lazy(() => import('modules/tools'));
const TradingCentralEmbed = lazy(() => import('modules/tradingCentralEmbed'));
const AnalystViews = lazy(() => import('modules/analystViews'));
const LeadJoin = lazy(() => import('modules/lead'));
const ReduceFees = lazy(() => import('modules/reduceFees'));
const TradingCalculators = lazy(() => import('modules/tradingCalculators'));
const VPS = lazy(() => import('modules/vps'));
const ConfirmPayment = lazy(() =>
  import('modules/payments/pages/Deposit/containers/ConfirmPaymentContainer'),
);
const Payments = lazy(() => import('modules/payments/containers/PaymentsContainer'));
const ProfileData = lazy(() => import('modules/profile/pages/ProfileData'));
const ProfileVerification = lazy(() => import('modules/profile/pages/Verification'));
const VerificationDetails = lazy(() => import('modules/verificationManagement/pages/ViewDetails'));
const EmailManagement = lazy(() => import('modules/emailManagement'));
const VerificationManagement = lazy(() => import('modules/verificationManagement'));
const UserDetails = lazy(() => import('modules/userManagement/pages/UserDetails'));
const UserManagement = lazy(() => import('modules/userManagement'));
const PaymentDetails = lazy(() => import('modules/paymentManagement/pages/ViewDetails'));
const PaymentManagement = lazy(() => import('modules/paymentManagement'));
const Security = lazy(() => import('modules/security'));
const Settings = lazy(() => import('modules/settings'));
const PageWarning = lazy(() => import('modules/pageWarning'));
const CustomFields = lazy(() => import('modules/customFields'));
const Notifications = lazy(() => import('modules/userNotifications'));
const RiskGroup = lazy(() => import('modules/riskGroup'));
const PaymentQueue = lazy(() => import('modules/paymentQueue'));
const MigrationRequests = lazy(() => import('modules/migrationRequests'));
const Operations = lazy(() => import('modules/operations'));
const TicketHistory = lazy(() =>
  import('modules/userManagement/pages/UserDetails/containers/TicketHistoryContainer'),
);
const UpgradeToPro = lazy(() => import('modules/upgradeToProV2/containers/UpgradeToProContainer'));

const FusionPlusAbout = lazy(() =>
  import('modules/myFollowers/containers/FusionPlusAboutContainer'),
);
const FusionPlusInvitations = lazy(() =>
  import('modules/myFollowers/containers/FusionPlusInvitationsContainer'),
);
const MyFollowersTrades = lazy(() => import('modules/myFollowers/containers/TradesContainer'));
const MyFollowers = lazy(() => import('modules/myFollowers'));
const MasterAccounts = lazy(() => import('modules/myFollowers/containers/MasterAccountsContainer'));
const MyFollowersWaitlist = lazy(() =>
  import('modules/myFollowers/containers/LeaderboardContainer'),
);

const AcceptFusionPlusInvitation = lazy(() =>
  import('modules/myFollowers/containers/AcceptFusionPlusInvitationContainer'),
);
const CryptoWaitlist = lazy(() => import('modules/cryptoWaitlist'));

const TradingCentralEmbedEconomicCalendar = () => <TradingCentralEmbed page="economicCalendar" />;
const TradingCalculatorsPublic = () => <TradingCalculators isPublic />;
const TradingCentralEmbedPublicEconomicCalendar = () => (
  <TradingCentralEmbed isPublic page="economicCalendarPublic" />
);
const TradingCentralEmbedTechnicalInsight = () => <TradingCentralEmbed page="technicalInsight" />;
const SignInInternal = () => <SignIn internal />;

export const getStaffRoutes = ({ isAdmin, isCountryManager }) => {
  return [
    {
      component: MigrationRequests,
      path: Routes.MIGRATION_REQUESTS,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
      visible: true,
    },
    {
      component: UserDetails,
      path: Routes.USER_MANAGEMENT_DETAILS_ID,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      visible: true,
    },
    {
      component: UserManagement,
      path: Routes.USER_MANAGEMENT,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.USER_MANAGEMENT,
      visible: true,
    },
    {
      component: TicketHistory,
      path: Routes.TICKET_HISTORY_ID,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      visible: true,
    },
    {
      component: PaymentDetails,
      path: Routes.PAYMENT_MANAGEMENT_DETAILS_ID,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      visible: true,
    },
    {
      component: PaymentManagement,
      path: Routes.PAYMENT_MANAGEMENT,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.PAYNENT_MANAGER,
      visible: true,
    },
    {
      component: EmailManagement,
      path: Routes.EMAIL_MANAGEMENT,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.EMAIL_MANAGEMENT,
      visible: true,
    },
    {
      component: VerificationDetails,
      path: Routes.VERIFICATION_MANAGEMENT_DETAILS_ID,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.VERIFICATION_MANAGEMENT,
      visible: true,
    },
    {
      component: VerificationManagement,
      path: Routes.VERIFICATION_MANAGEMENT,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.VERIFICATION_MANAGEMENT,
      visible: true,
    },
    {
      component: PageWarning,
      path: Routes.PAGE_WARNING,
      isPrivate: true,
      access: [Roles.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: RiskGroup,
      path: Routes.RISK_GROUP,
      isPrivate: true,
      access: [Roles.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: PaymentQueue,
      path: Routes.PAYMENT_QUEUE,
      isPrivate: true,
      access: [Roles.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: Notifications,
      path: Routes.NOTIFICATIONS,
      isPrivate: true,
      access: [Roles.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: Security,
      path: Routes.SECURITY,
      isPrivate: true,
      access: [Roles.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: Operations,
      path: Routes.OPERATIONS,
      isPrivate: true,
      access: [Roles.ADMIN],
      // access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.OPERATIONS,
      visible: true,
    },
    {
      component: Settings,
      path: Routes.SETTINGS,
      isPrivate: true,
      access: [Roles.MANAGER, Roles.ADMIN],
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
      visible: true,
    },
    {
      component: Dashboard,
      path: Routes.DASHBOARD,
      isPrivate: true,
      access: [Roles.ADMIN, Roles.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.DASHBOARD,
      visible: isAdmin || isCountryManager,
    },
    {
      component: CustomFields,
      path: Routes.CUSTOM_FIELDS,
      isPrivate: true,
      access: [Roles.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
      exact: true,
    },
  ];
};

export const getAuthRoutes = () => {
  return [
    {
      component: OutdatedPassword,
      path: Routes.AUTH_OUTDATED_PASSWORD,
      isPrivate: false,
      visible: true,
    },
    {
      component: ResetPassword,
      path: Routes.AUTH_RESET_PASSWORD,
      isPrivate: false,
      visible: true,
    },
    {
      component: MfaInput,
      path: Routes.AUTH_MFA_INPUT,
      isPrivate: true,
      visible: true,
    },
    {
      component: MfaSetup,
      path: Routes.AUTH_MFA_SETUP,
      isPrivate: true,
      visible: true,
    },
    {
      component: MfaRequired,
      path: Routes.AUTH_MFA_REQUIRED,
      isPrivate: false,
      visible: true,
    },
    {
      component: ForgotPassword,
      path: Routes.AUTH_FORGOT_PASSWORD,
      isPrivate: false,
      visible: true,
    },
    {
      component: Migration,
      path: Routes.AUTH_MIGRATION,
      isPrivate: false,
      visible: true,
    },
    {
      component: ForcePasswordChange,
      path: Routes.AUTH_FORCE_PASSWORD_CHANGE,
      isPrivate: true,
      visible: true,
    },
    {
      component: SignInInternal,
      path: Routes.AUTH_SIGN_IN_INTERNAL,
      isPrivate: false,
      visible: true,
    },
    {
      component: SignIn,
      path: Routes.SIGNIN,
      isPrivate: false,
      exact: true,
      visible: true,
    },
    {
      component: SignUp,
      path: Routes.SIGNUP,
      isPrivate: false,
      visible: true,
    },
    {
      component: EmailVerification,
      path: Routes.AUTH_EMAIL_VERIFICATION,
      isPrivate: false,
      visible: true,
    },
  ];
};

export const getPaymentRoutes = () => {
  return [
    {
      component: ConfirmPayment,
      path: Routes.CONFIRM_PAYMENT,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: Payments,
      path: [Routes.PAYMENT, Routes.PAYMENTS],
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: BankTransfer,
      path: [Routes.BANK_TRANSFER_PAYMENT],
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: DepositMonoovaContainer,
      path: [Routes.PAY_ID_PAYMENT],
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
  ];
};

export const getNewsRoutes = () => {
  return [
    {
      component: DiscoverDashboard,
      path: Routes.MARKET_BUZZ_DASHBOARD_ID,
      access: [Roles.CLIENT],
      isPrivate: true,
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
    },
    {
      component: Discover,
      path: Routes.MARKET_BUZZ,
      access: [Roles.CLIENT],
      isPrivate: true,
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
    },
    {
      component: MyFeedDetails,
      path: Routes.MY_FEEDS_WITH_ID,
      access: [Roles.CLIENT],
      isPrivate: true,
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
    },
    {
      component: MyFeeds,
      path: Routes.MY_FEEDS,
      access: [Roles.CLIENT],
      isPrivate: true,
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
      exact: true,
    },
    {
      component: Sentiment,
      path: Routes.SENTIMENT,
      access: [Roles.CLIENT],
      isPrivate: true,
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
    },
    {
      component: NewsSearch,
      path: Routes.NEWS_SEARCH,
      access: [Roles.CLIENT],
      isPrivate: true,
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
    },
  ];
};

export const getFusionPlusRoutes = ({ allowCopyAccount, joinFusionPlus }) => {
  return [
    {
      component: FusionPlusAbout,
      path: Routes.PRIME_PLUS_ABOUT,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: FusionPlusInvitations,
      path: Routes.PRIME_PLUS_INVITATIONS,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: MyFollowers,
      path: Routes.PRIME_PLUS_MY_FOLLOWERS,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: AcceptFusionPlusInvitation,
      path: Routes.PRIME_PLUS_FOLLOWER_JOIN,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: MyFollowersTrades,
      path: Routes.PRIME_PLUS_TRADES,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      isPrivate: true,
      visible: allowCopyAccount && joinFusionPlus,
    },
    {
      component: MasterAccounts,
      path: Routes.PRIME_PLUS_MASTER_ACCOUNTS,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: MyFollowersWaitlist,
      path: Routes.PRIME_PLUS_MY_FOLLOWERS_WAITLIST,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
  ];
};

export const getClientRoutes = ({
  allowCopyAccount,
  joinFusionPlus,
  allowUpgradeToPro,
  allowMigrateMyAccount,
}) => {
  return [
    ...getPaymentRoutes(),
    {
      component: MyAccounts,
      path: Routes.MY_ACCOUNTS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: Tools,
      path: Routes.PLATFORMS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    ...getFusionPlusRoutes({ allowCopyAccount, joinFusionPlus }),
    {
      component: MyPerformance,
      path: Routes.MY_PERFORMANCE,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: UserPerformance,
      path: Routes.USER_PERFORMANCE,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: TradingCentralEmbedEconomicCalendar,
      path: Routes.ECONOMIC_CALENDAR,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: MigrateMyAccount,
      path: Routes.MIGRATE_MY_ACCOUNT,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: allowMigrateMyAccount,
    },
    {
      component: UpgradeToPro,
      path: Routes.UPGRADE_TO_PRO_V_2,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: allowUpgradeToPro,
    },
    {
      component: ContactUs,
      path: Routes.CONTACT_US,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: DownloadsContainer,
      path: Routes.MT4_DOWNLOADS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: DownloadMobileContainer,
      path: Routes.MT4_DOWNLOADS_MOBILE,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: ResearchContainer,
      path: Routes.TRADE_IDEAS_RESEARCH,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: TechnicalAnalysisContainer,
      path: Routes.TRADE_IDEAS_TECHNICAL_ANALYSIS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: MarketReportContainer,
      path: Routes.TRADE_IDEAS_MARKET_REPORT,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: ImpactNewsContainer,
      path: Routes.TRADE_IDEAS_IMPACT_NEWS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: CorrelatingContainer,
      path: Routes.TRADE_IDEAS_CORRELATING,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: PerformanceStatisticsContainer,
      path: Routes.TRADE_IDEAS_PERFORMANCE_STATISTICS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: VolatilityAnalysisContainer,
      path: Routes.TRADE_IDEAS_VOLATILITY_ANALYSIS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: NewsSentimentContainer,
      path: Routes.LEARNINGS_NEWS_SENTIMENT,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: MessagingAndAlertsContainer,
      path: Routes.MESSAGING_AND_ALERTS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: EducationContainer,
      path: Routes.LEARNINGS_NEWS_EDUCATION,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: ProfileData,
      path: Routes.PROFILE,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
      exact: true,
    },
    {
      component: ProfileVerification,
      path: Routes.PROFILE_VERIFICATION,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: TradingCalculators,
      path: Routes.TRADING_CALCULATORS,
      isPrivate: true,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: TradingCalculatorsPublic,
      path: Routes.PUBLIC_CALCULATORS,
      isPrivate: false,
      access: [Roles.CLIENT],
      visible: true,
    },
    {
      component: TradingCentralEmbedTechnicalInsight,
      path: Routes.TECHNICAL_INSIGHT,
      isPrivate: true,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
    },
    {
      component: TradingCentralEmbedPublicEconomicCalendar,
      path: Routes.PUBLIC_ECONOMIC_CALENDAR,
      isPrivate: false,
      visible: true,
    },
    {
      component: ReduceFees,
      path: Routes.REDUCE_FEES,
      access: [Roles.CLIENT],
      isPrivate: true,
      visible: false,
    },
    ...getNewsRoutes(),
    {
      component: AnalystViews,
      path: Routes.ANALYST_VIEWS,
      isPrivate: true,
      access: [Roles.CLIENT],
      hub: [GLOBAL_PRIME, GLOBAL_PRIME_X],
      visible: true,
    },
    {
      component: LeadJoin,
      path: Routes.LEAD_JOIN,
      isPrivate: false,
      visible: true,
    },
    {
      component: VPS,
      path: Routes.VPS,
      isPrivate: true,
      visible: true,
    },
    {
      component: CryptoWaitlist,
      path: Routes.CRYPTO_WAITLIST,
      isPrivate: true,
      visible: true,
    },
  ];
};

export const getAllRoutes = ({
  isAdmin,
  isCountryManager,
  allowCopyAccount,
  joinFusionPlus,
  allowUpgradeToPro,
  allowMigrateMyAccount,
}) => {
  return [
    ...getAuthRoutes(),
    ...getStaffRoutes({ isAdmin, isCountryManager }),
    ...getClientRoutes({
      allowCopyAccount,
      joinFusionPlus,
      allowUpgradeToPro,
      allowMigrateMyAccount,
    }),
    {
      component: Home,
      path: Routes.HOME,
      isPrivate: false,
      exact: true,
      allAuthorized: true,
      visible: true,
    },
  ];
};
