import get from 'lodash/get';
import { all, put, takeEvery, select } from 'redux-saga/effects';
import * as notificationsActions from 'modules/notifications/actions';
import * as actions from '../actions';
import * as modalActions from 'modules/common/CustomModal/actions';
import {
  CREATE_NOTIFICATION_MODAL,
  CREATE_NOTIFICATION_FORM,
  SHOW_NOTIFICATIONS_MODAL,
  ROUTES_MAPER,
} from '../constants';
import * as authSelectors from 'modules/auth/selectors';
import { reset } from 'redux-form';
import { Routes } from 'constants/routeConstants';
import { push } from 'modules/reduxNavigation/actions';
import { check } from '../../permissions';

function* showErrorNotification(action) {
  yield put(
    notificationsActions.showNotificationError({
      key: get(action, 'error.data.message', `common:errors.unexpectedError`),
    }),
  );
}

function* createNotificationsOpen() {
  yield put(modalActions.openModal(CREATE_NOTIFICATION_MODAL));
}

function* createNotificationsClose() {
  yield put(reset(CREATE_NOTIFICATION_FORM));
  yield put(modalActions.closeModal(CREATE_NOTIFICATION_MODAL));
}

function* createNotificationsSuccess(action) {
  const { type, skip, limit } = action.payload;
  yield put(push(`${Routes.NOTIFICATIONS}/${ROUTES_MAPER[type]}`));
  yield put(actions.getNotificationsRequest({ type, skip, limit }));

  yield createNotificationsClose();
}

function* editNotificationOpen() {
  yield put(modalActions.openModal(CREATE_NOTIFICATION_MODAL));
}

function* getUserNotificationsOpen() {
  const userId = yield select(authSelectors.getUserId);
  yield put(modalActions.openModal(SHOW_NOTIFICATIONS_MODAL));
  yield put(actions.getUserNotificationsRequest({ userId }));
}

function* getUserNotificationsClose() {
  yield put(modalActions.closeModal(SHOW_NOTIFICATIONS_MODAL));
}

function* markAllAsReadSuccess() {
  yield getUserNotificationsClose();
  const userId = yield select(authSelectors.getUserId);
  yield put(actions.getUserNotificationsCountRequest(userId));
}

function* handleCheckIfNewUserNotificationsSuccess(action) {
  const isNewNotifications = get(action, 'response.data.exists', false) === true;
  const userRole = yield select(authSelectors.getUserRole);
  const userId = yield select(authSelectors.getUserId);
  const isPermissible = check(userRole, 'userNotifications:check');

  if (isNewNotifications && isPermissible) {
    yield put(actions.getUserNotificationsOpen(userId));
  }
}

export default function* watchRequest() {
  yield all([
    takeEvery(actions.createNotificationsOpen, createNotificationsOpen),
    takeEvery(actions.createNotificationsClose, createNotificationsClose),
    takeEvery(actions.createNotificationsSuccess, createNotificationsSuccess),
    takeEvery(actions.createNotificationsFail, showErrorNotification),
    takeEvery(actions.updateNotificationsSuccess, createNotificationsSuccess),
    takeEvery(actions.getNotificationsFail, showErrorNotification),
    takeEvery(actions.editNotificationOpen, editNotificationOpen),
    takeEvery(actions.editNotificationClose, createNotificationsClose),
    takeEvery(actions.getUserNotificationsOpen, getUserNotificationsOpen),
    takeEvery(actions.getUserNotificationsClose, getUserNotificationsClose),
    takeEvery(actions.markAllAsReadSuccess, markAllAsReadSuccess),
    takeEvery(actions.checkIfNewUserNotificationsSuccess, handleCheckIfNewUserNotificationsSuccess),
  ]);
}
