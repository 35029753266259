import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Collapse from 'material-latest/Collapse';
import ListItemIcon from 'material-latest/ListItemIcon';
import Box from 'material-latest/Box';
import MenuItem from 'material-latest/MenuItem';
import MenuList from 'material-latest/MenuList';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { getTranslation } from 'utils/compositeTranslationHandler';
import TPTypography from 'components/TP-UI/TPTypography';
import TPNavMenuListItem from '../TPNavMenuListItem';

import styles from './styles';

const TPNavMenuListAccordion = ({
  id,
  selectedRoute,
  label,
  iconComponent: IconComponent,
  expanded = false,
  selected = false,
  items,
  onClick,
  onNestedItemClick,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <>
      <MenuItem
        id={id}
        key={id}
        sx={[styles.menuItem, selected && styles.selectedMenuItem]}
        onClick={handleClick}>
        <Box sx={styles.menuItemLabel}>
          <ListItemIcon sx={styles.iconContainer}>
            {typeof IconComponent === 'string' ? (
              <img src={IconComponent} alt={label} />
            ) : (
              <IconComponent />
            )}
          </ListItemIcon>
          <TPTypography className={styles.label}>{label}</TPTypography>
        </Box>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MenuList sx={[styles.nestedList, selected && styles.selectedNestedList]}>
          {items.map((item) => (
            <TPNavMenuListItem
              id={item.id}
              key={item.id}
              label={getTranslation(t, item.label)}
              selected={selectedRoute.includes(item.path)}
              iconComponent={item.iconComponent}
              path={item.path}
              className={[
                styles.nestedItem,
                selected && styles.nestedItemInSelectedMenu,
                selectedRoute.includes(item.path) && styles.selectedNestedItem,
              ]}
              onClick={onNestedItemClick}
            />
          ))}
        </MenuList>
      </Collapse>
    </>
  );
};

TPNavMenuListAccordion.propTypes = {
  id: PropTypes.string,
  selectedRoute: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  iconComponent: PropTypes.elementType.isRequired,
  expanded: PropTypes.bool,
  selected: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      id: PropTypes.string,
      iconComponent: PropTypes.elementType,
      path: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  onNestedItemClick: PropTypes.func,
};

export default TPNavMenuListAccordion;
