import { combineActions, handleActions } from 'redux-actions';

import * as mfaActionCreators from '../pages/MfaInput/actions';
import * as signUpActionCreators from '../pages/SignUp/actions';
import * as signInActionCreators from '../pages/SignIn/actions';
import * as migrationActionCreators from '../pages/Migration/actions';
import * as actionCreators from '../actions';
import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';
import * as leadActionCreators from 'modules/lead/actions';
import { forcePasswordChangeSuccess } from '../pages/ForcePasswordChange/actions';
import * as profileActionCreators from 'modules/profile/pages/ProfileData/actions';
import { setupMfaRequiredSuccess } from '../pages/MfaRequired/actions';
import { verifyEmailSuccess } from '../pages/EmailVerification/actions';
import { cleanupAuth } from '../actions';
const defaultState = null;

export default handleActions(
  {
    [combineActions(
      signUpActionCreators.signupSuccess,
      signInActionCreators.signinSuccess,
      signUpActionCreators.googleAuthSuccess,
      migrationActionCreators.userMigrationSuccess,
      mfaActionCreators.validateMfaSuccess,
      userActionCreators.signinByEmailSuccess,
      leadActionCreators.leadUserJoinSuccess,
      forcePasswordChangeSuccess,
      profileActionCreators.changePasswordSuccess,
      setupMfaRequiredSuccess,
      verifyEmailSuccess,
    )](state, action) {
      return action.response.data.token;
    },
    [combineActions(signInActionCreators.signinNonfxSuccess)](state, action) {
      return action.payload.token;
    },
    [actionCreators.refreshToken](state, action) {
      return action.payload;
    },
    [signInActionCreators.updateToken](state, action) {
      return action.payload.data.token;
    },
    [cleanupAuth]: () => {
      return defaultState;
    },
  },
  defaultState,
);
