import omit from 'lodash/omit';
import hideMessage from 'modules/common/HideMessages/reducers';
import i18n from 'modules/common/LanguageToggle/reducers';
import pagination from 'modules/common/PaginationBar/reducers';
import reduceFees from 'modules/reduceFees/reducers';
import tradingCalculators from 'modules/tradingCalculators/reducers';
import tradingCentralEmbed from 'modules/tradingCentralEmbed/reducers';
import { reducer as formReducer } from 'redux-form';
import { createTransform, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import security from './modules/./security/reducers';
import accounts from './modules/accounts/reducers';
import analystData from './modules/analystViews/reducers';
import { apiCalls } from './modules/api/reducers';
import auth from './modules/auth/reducers';
import selectHub from './modules/auth/reducers/selectHub';
import modal from './modules/common/CustomModal/reducers';
import slidesForm from './modules/common/InfoCard/Fields/SliderBlock/reducers';
import contactUs from './modules/contactUs/reducers';
import discover from './modules/discover/reducers';
import emailManagement from './modules/emailManagement/reducers';
import geolocation from './modules/geolocation/reducers';
import migrateMyAccount from './modules/migrateMyAccount/reducers';
import notifications from './modules/notifications/reducers';
import paymentManagement from './modules/paymentManagement/reducers';
import payments from './modules/payments/reducers';
import profile from './modules/profile/reducers';
import user from './modules/user/reducers';
import userManagement from './modules/userManagement/reducers';
import verificationManagement from './modules/verificationManagement/reducers';
import myFeedDetails from './modules/discover/pages/MyFeedDetails/reducers';
import newsSearch from './modules/discover/pages/Search/reducers';
import partnerTransfer from './modules/payments/pages/PartnerTransfer/reducers';
import pageWarning from './modules/pageWarning/reducers';
import customFields from './modules/customFields/reducers';
import customFieldsAnswers from './modules/userManagement/pages/UserDetails/components/CustomFieldsAnswers/reducers';
import userNotifications from './modules/./userNotifications/reducers';
import dashboard from './modules/dashboard/reducers';
import migrationApproveRequests from './modules/migrationRequests/reducers';
import operations from './modules/operations/reducers';
import myFollowers from './modules/myFollowers/reducers';
import myPerformance from './modules/myPerformance/reducers';
import lpoa from './modules/lpoa/reducers';
import riskGroups from './modules/riskGroup/reducers';
import paymentQueue from './modules/paymentQueue/reducers';
import { sideBarReducer } from './modules/menu/components/SideMenu/reducers';
import profileQuizReducer from './modules/profileQuiz/reducers';
import AML, { persistentReducer as hideCompleteAML } from 'modules/common/AML/reducers/index';
import TMD from 'modules/common/TMD/reducers';
import upgradeToPro from 'modules/upgradeToProV2/reducers';
import evidenceStatement from './modules/evidenceUploadPortal/reducers';
import basenameReducer from 'components/App/reducer';
import reduxNavigationReducer from './modules/reduxNavigation/reducers';
import day1Transfer from './modules/cryptoWaitlist/reducers';

const authTransform = createTransform(
  (state) => state,
  (state) => omit(omit(state, 'isFirstVisit'), 'signIn.showCaptcha'),
  { whitelist: ['auth'] },
);

const rootPersistConfig = {
  key: 'authData',
  storage: storage,
  whitelist: ['auth', 'hideMessage', 'hideCompleteAML'],
  transforms: [authTransform],
};

const rootReducer = () =>
  persistCombineReducers(rootPersistConfig, {
    form: formReducer,
    apiCalls,
    quiz: profileQuizReducer,
    auth,
    selectHub,
    notifications,
    accounts,
    profile,
    payments,
    modal,
    user,
    userManagement,
    paymentManagement,
    verificationManagement,
    contactUs,
    geolocation,
    slidesForm,
    emailManagement,
    pagination,
    tradingCentralEmbed,
    tradingCalculators,
    i18n,
    reduceFees,
    hideMessage,
    migrateMyAccount,
    analystData,
    discover,
    security,
    myFeedDetails,
    newsSearch,
    partnerTransfer,
    pageWarning,
    userNotifications,
    dashboard,
    migrationApproveRequests,
    operations,
    myFollowers,
    myPerformance,
    lpoa,
    riskGroups,
    paymentQueue,
    customFields,
    customFieldsAnswers,
    sideBar: sideBarReducer,
    AML,
    hideCompleteAML,
    TMD,
    upgradeToPro,
    evidenceStatement,
    app: basenameReducer,
    navigation: reduxNavigationReducer,
    day1Transfer,
  });

export default rootReducer;
