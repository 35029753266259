import { all } from 'redux-saga/effects';

import signupSaga from '../pages/SignUp/sagas';
import signinSaga from '../pages/SignIn/sagas';
import emailVerificationSaga from '../pages/EmailVerification/sagas';
import resetPasswordSaga from '../pages/ResetPassword/sagas';
import forgotPasswordSaga from '../pages/ForgotPassword/sagas';
import migrationSaga from '../pages/Migration/sagas';
import mfaInputSaga from '../pages/MfaInput/sagas';
import loginSinceRejection from './loginSinceRejection';
import logout from './logout';
import updateUserState from './updateUserState';
import forcePasswordChangeSaga from '../pages/ForcePasswordChange/sagas';
import logoutAsCurrentUser from './logoutAsCurrentUser';

function* authSaga() {
  yield all([
    signupSaga(),
    signinSaga(),
    resetPasswordSaga(),
    forgotPasswordSaga(),
    migrationSaga(),
    mfaInputSaga(),
    loginSinceRejection(),
    logout(),
    forcePasswordChangeSaga(),
    updateUserState(),
    logoutAsCurrentUser(),
    emailVerificationSaga(),
  ]);
}

export default authSaga;
