import { all, put, takeEvery } from 'redux-saga/effects';
import { stopSubmit, initialize, reset } from 'redux-form';
import { push } from 'modules/reduxNavigation/actions';
import get from 'lodash/get';
import includes from 'lodash/includes';

import { Routes } from 'constants/routeConstants';
import * as geolocationActions from 'modules/geolocation/actions';
import * as actionCreators from '../actions';
import { SIGNUP_FORM, RESTRICTED_COUNTRY_MODAL } from '../constants';
import { closeModal, openModal } from 'modules/common/CustomModal/actions';
import config from 'config';
import { showNotificationError } from 'modules/notifications/actions';
import { DEFAULT_LANG, LANG_COOKIE_NAME, AUSTRALIA } from './../../../../../constants/index';
import Cookies from 'js-cookie';
import i18n from 'i18n';
import {
  GOOGLE_AUTH_CREATE_PASSWORD_FORM,
  GOOGLE_AUTH_CREATE_PASSWORD_MODAL,
} from 'modules/auth/constants';

function* redirectToDashboard() {
  yield put(reset(GOOGLE_AUTH_CREATE_PASSWORD_FORM));
  yield put(closeModal(GOOGLE_AUTH_CREATE_PASSWORD_MODAL));
  yield put(push(Routes.HOME));
}

function* closeGoogleAuthModal() {
  yield put(reset(GOOGLE_AUTH_CREATE_PASSWORD_FORM));
  yield put(closeModal(GOOGLE_AUTH_CREATE_PASSWORD_MODAL));
}

function* showError(action) {
  yield put(
    stopSubmit(SIGNUP_FORM, {
      _error: get(action, 'error.data.message'),
    }),
  );
}

function* preInsertSignUp(action) {
  yield all([put(initialize(SIGNUP_FORM, { email: action.payload })), put(push(Routes.SIGNUP))]);
}

function* checkAllowedCountries({ response }) {
  const userCountry = response.data.country;

  if (userCountry === AUSTRALIA) {
    i18n.changeLanguage(DEFAULT_LANG);
    Cookies.set(LANG_COOKIE_NAME, DEFAULT_LANG, { path: '/', sameSite: 'none', secure: true });
  }

  const isRestrictedCountry =
    response &&
    response.data &&
    includes(Object.keys(config.RESTRICTED_COUNTRIES), response.data.country);
  if (isRestrictedCountry) {
    yield put(openModal(RESTRICTED_COUNTRY_MODAL));
  }
}

function* handleGoogleAuthError(action) {
  const errorMessage = get(action, 'error.data.message', 'Error');
  yield put(showNotificationError(errorMessage));
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.signupSuccess, redirectToDashboard),
    takeEvery(actionCreators.googleAuthSuccess, redirectToDashboard),
    takeEvery(actionCreators.googleAuthFail, closeGoogleAuthModal),
    takeEvery(actionCreators.signupFail, showError),
    takeEvery(actionCreators.googleAuthFail, handleGoogleAuthError),
    takeEvery(actionCreators.preInsertSignUp, preInsertSignUp),
    takeEvery(geolocationActions.getCountryByIpSuccess, checkAllowedCountries),
  ]);
}
