export default (theme) => {
  const { climCustomButton, climCustomLink, climCustomLogo } = theme.climCustomStyles;

  return {
    title: {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '34px',
      textAlign: 'center',
      color: theme.palette.text.main,
    },
    formWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 16,
      width: '100%',
    },
    form: {
      width: 460,
      background: theme.signIn.backgroundColor,
      border: theme.signIn.border,
      padding: 40,
      [theme.breakpoints.down('xs')]: {
        padding: 16,
        width: '100%',
      },
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      paddingTop: 24,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 12,
      },
      width: '100%',
      // overflow: 'auto',
    },
    mainLayout: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 16,
      '@media (max-width:680px)': {
        paddingTop: 32,
      },
    },
    textField: {
      marginTop: 16,
    },
    formError: {
      ...theme.textError,
      top: 14,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        top: 8,
      },
    },
    errorIcon: {
      fontSize: 12,
      marginRight: 7,
    },
    forgotPasswordWrapper: {
      width: '100%',
      textAlign: 'right',
    },
    submitButton: {
      marginTop: 16,
    },
    customLinkWrapper: {
      marginTop: 24,
      textAlign: 'center',
    },
    customLinkText: {
      margin: '0 0 4px 0',
      cursor: 'pointer',
      color: theme.palette.text.main,
      fontSize: 14,
      fontWeight: 300,
    },
    loader: {
      height: 50,
      padding: 20,
      marginTop: 9,
      textAlign: 'center',
    },
    climCustomButton,
    climCustomLink,
    climCustomLogo,
    hubSelect: {
      width: '100%',
      borderRadius: 6,
      padding: 4,
      marginBottom: 70,
    },
    logo: {
      marginBottom: 40,
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    image: {
      marginBottom: '70px',
    },
    header: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
      textAlign: 'center',
      color: theme.palette.textColor,
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.simpleGrey,
    },
    spaceForFooter: {
      minHeight: '70vh',
    },
  };
};
