import React, { useCallback, useState } from 'react';
import { compose } from 'redux';
import { Grid, withStyles } from '@material-ui/core';
import { Field, reduxForm, change, formValues } from 'redux-form';
import PrimaryButton from '../../../common/PrimaryButton';
import FormSelect from '../../../common/FormSelect';
import { CUSTOM_DATE_RANGE_SELECTOR } from '../../constants';
import { useDispatch } from 'react-redux';
import { getDateRangeValuesByCustomType } from '../../utils/dateRange';
import Checkbox from '../../../common/Checkbox';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from 'material-latest';

import { SIZES } from 'components/TP-UI/constants';
import TPDatePicker from 'components/TP-UI/TPDatePicker';

import styles, { styles as componentStyles } from './styles';

const HeaderPanel = ({
  classes,
  handleSubmit,
  form,
  additionalFilter,
  expandable,
  shouldCompare,
  exportData,
}) => {
  const dispatch = useDispatch();
  const [customDateRangeItems, setCustomDateRangeItems] = useState(CUSTOM_DATE_RANGE_SELECTOR);
  const [customCompareDate, setCustomCompareDate] = useState(CUSTOM_DATE_RANGE_SELECTOR);
  const [expanded, setExpanded] = useState(false);

  const exportDataHandler = () => {
    exportData();
  };

  const onCustomRangeChange = useCallback(
    (value) => {
      const { dateFrom, dateTo } = getDateRangeValuesByCustomType(value);
      dispatch(change(form, 'dateFrom', dateFrom));
      dispatch(change(form, 'dateTo', dateTo));
      setCustomDateRangeItems([...CUSTOM_DATE_RANGE_SELECTOR]);
    },
    [dispatch, form],
  );

  const onDateChange = useCallback(() => {
    setCustomDateRangeItems([
      { label: 'Custom', value: 'custom', disabled: true },
      ...CUSTOM_DATE_RANGE_SELECTOR,
    ]);
    dispatch(change(form, 'customDateSelector', 'custom'));
  }, [dispatch, form]);

  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon;

  const toggleExpandFilter = useCallback(() => setExpanded(!expanded), [expanded]);

  const onCompareRangeChange = useCallback(
    (value) => {
      const { dateFrom, dateTo } = getDateRangeValuesByCustomType(value);
      dispatch(change(form, 'dateFromCompare', dateFrom));
      dispatch(change(form, 'dateToCompare', dateTo));
      setCustomCompareDate([...CUSTOM_DATE_RANGE_SELECTOR]);
    },
    [dispatch, form],
  );

  const onCompareDateChange = useCallback(() => {
    setCustomCompareDate([
      { label: 'Custom', value: 'custom', disabled: true },
      ...CUSTOM_DATE_RANGE_SELECTOR,
    ]);
    dispatch(change(form, 'compareDateSelector', 'custom'));
  }, [dispatch, form]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container className={classes.root} alignItems="center" justify="space-between">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item className={classes.customSelector}>
                <Field
                  onChange={onCustomRangeChange}
                  name="customDateSelector"
                  id="customDateSelector"
                  component={FormSelect}
                  items={customDateRangeItems}
                  multiple={false}
                />
              </Grid>
              <Grid item>
                <Box sx={componentStyles.dateContainer}>
                  <Field
                    name="dateFrom"
                    id="dateFrom"
                    component={TPDatePicker}
                    onBlur={onDateChange}
                    textFieldClassName={[componentStyles.dateTextField]}
                    reservedErrorSpace={false}
                    size={SIZES.SMALL}
                    showStartAdornment={false}
                  />
                </Box>
              </Grid>
              <Grid>
                <Box mx={1} display="inline-flex" alignItems="center">
                  -
                </Box>
              </Grid>
              <Grid item>
                <Box sx={componentStyles.dateContainer}>
                  <Field
                    name="dateTo"
                    id="dateTo"
                    component={TPDatePicker}
                    onChange={onDateChange}
                    textFieldClassName={[componentStyles.dateTextField]}
                    reservedErrorSpace={false}
                    size={SIZES.SMALL}
                    showStartAdornment={false}
                  />
                </Box>
              </Grid>
              {additionalFilter && (
                <Grid className={classes.additionalFilter}>{additionalFilter}</Grid>
              )}
              <Grid item>
                <PrimaryButton
                  color="secondary"
                  label="Update"
                  className={classes.button}
                  size="small"
                  type="submit"
                />
              </Grid>
            </Grid>
          </Grid>

          {expandable ? (
            <Grid item>
              <ExpandIcon className={classes.icon} onClick={toggleExpandFilter} />
            </Grid>
          ) : null}
        </Grid>
        {expanded && (
          <Grid className={classes.root} item xs={12}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item className={classes.textBetween}>
                    <Field name="shouldCompare" component={Checkbox} label="Compare to:" />
                  </Grid>
                  <Grid item className={classes.customSelector}>
                    <Field
                      onChange={onCompareRangeChange}
                      name="compareDateSelector"
                      id="compareDateSelector"
                      component={FormSelect}
                      items={customCompareDate}
                      multiple={false}
                      disabled={!shouldCompare}
                    />
                  </Grid>
                  <Grid item>
                    <Box sx={componentStyles.dateContainer}>
                      <Field
                        name="dateFromCompare"
                        id="dateFromCompare"
                        component={TPDatePicker}
                        onChange={onCompareDateChange}
                        textFieldClassName={[componentStyles.dateTextField]}
                        reservedErrorSpace={false}
                        size={SIZES.SMALL}
                        showStartAdornment={false}
                        disabled={!shouldCompare}
                      />
                    </Box>
                  </Grid>
                  <Grid>
                    <Box mx={1} display="inline-flex" alignItems="center">
                      -
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={componentStyles.dateContainer}>
                      <Field
                        name="dateToCompare"
                        id="dateToCompare"
                        component={TPDatePicker}
                        onChange={onCompareDateChange}
                        textFieldClassName={[componentStyles.dateTextField]}
                        reservedErrorSpace={false}
                        size={SIZES.SMALL}
                        showStartAdornment={false}
                        disabled={!shouldCompare}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      color="primary"
                      label="Export"
                      className={classes.button}
                      size="small"
                      type="button"
                      disabled={!shouldCompare}
                      onClick={exportDataHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

const HeaderPanelStyled = withStyles(styles)(HeaderPanel);

export default compose(
  reduxForm(),
  formValues('shouldCompare'),
  withStyles(styles),
)(HeaderPanelStyled);
