import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import EmailVerification from '../components/EmailVerification/EmailVerification';
import { useDispatch, useSelector } from 'react-redux';
import { sendVerificationEmailRequest } from '../actions';
import { Footer } from '../../../../common/Footer';
import * as actions from '../../EmailVerification/actions';
import { Box } from 'material-latest';
import loader from 'static/loader.gif';
import { getCanResend, getVerificationError } from '../selectors';
import { closeModal } from '../../../../common/CustomModal/actions';
import { EMAIL_VERIFICATION_MODAL } from '../../SignIn/constants';
import EmailVerificationModal from '../../SignIn/components/EmailVerificationModal';
import { Routes as ROUTES } from '../../../../../constants/routeConstants';
import { useHistory } from 'react-router';
import { createPendingSelector } from 'modules/api/selectors';
import { useQuery } from '../../SignUp/hooks/useQuery';

const EmailVerificationContainer = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token') || null;
  const [loading, setLoading] = useState(true);
  const verificationError = useSelector(getVerificationError);
  const history = useHistory();
  const canResend = useSelector(getCanResend);
  const isPending = useSelector(createPendingSelector(sendVerificationEmailRequest));

  const checkToken = useCallback(async () => {
    try {
      await dispatch(actions.verifyEmailRequest({ verificationToken: token }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, token]);

  useEffect(() => {
    checkToken();
  }, []);

  const handleSendEmail = useCallback(() => {
    dispatch(sendVerificationEmailRequest({ verificationToken: token }));
  }, [dispatch, token]);

  const closeEmailVerificationModal = useCallback(() => {
    dispatch(closeModal(EMAIL_VERIFICATION_MODAL));
    history.push(ROUTES.SIGNIN);
  }, [dispatch, history]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <img src={loader} alt={'loader'} />
      </Box>
    );
  }

  return (
    <Box>
      <EmailVerification
        token={token}
        verificationError={verificationError}
        onSendEmail={handleSendEmail}
        canResend={canResend}
        isPending={isPending}
      />
      <EmailVerificationModal
        modalName={EMAIL_VERIFICATION_MODAL}
        closeModal={closeEmailVerificationModal}
      />
      <Footer />
    </Box>
  );
};

export default withRouter(EmailVerificationContainer);
