import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { checkNonLatin, required } from 'utils/validation/fieldValidationRules';
import TPGrid from 'components/TP-UI/TPGrid';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import TPButton from 'components/TP-UI/TPButton';
import TPTextField from 'components/TP-UI/TPTextField';
import TPCheckbox from 'components/TP-UI/TPCheckbox';

import styles from './styles';

const MfaInputForm = ({ handleSubmit, error, isPending }) => {
  const { t } = useTranslation('auth');

  return (
    <form sx={styles.form} onSubmit={handleSubmit} noValidate>
      <TPGrid container spacing={2} direction="column">
        {error && (
          <TPGrid item xs={12}>
            <TPErrorMessage error={error} size="medium" />
          </TPGrid>
        )}
        <TPGrid item container spacing={1} direction="column">
          <TPGrid item xs={12}>
            <Field
              name="code"
              label={t('mfa.inputLabel')}
              placeholder={t('mfa.inputPlaceholder')}
              type="text"
              component={TPTextField}
              autofocus
              fullWidth
              required
              validate={[checkNonLatin, required]}
            />
          </TPGrid>
          <TPGrid item xs={12}>
            <Field
              name="rememberMe"
              component={TPCheckbox}
              label={t('mfa.rememberMe')}
              reservedErrorSpace={false}
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton fullWidth primary size="large" type="submit" loading={isPending}>
            {t('mfa.button')}
          </TPButton>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

MfaInputForm.propTypes = {
  form: PropTypes.string,
  isPending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOf([PropTypes.string, PropTypes.object, null]),
};

export default reduxForm()(MfaInputForm);
