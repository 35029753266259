import { palette } from './palette';
import { fontFamily, fontSize, fontWeight } from '../font';
import { typographyFonts } from './typographyFonts';

export const common = {
  formControl: {
    root: {
      borderColor: palette.dividerCustom.dark,
      color: palette.text.dark,
      borderRadius: '3px',
      borderWidth: '1px',
      paddingTop: 12,
      paddingBottom: 13,
      fontSize: fontSize._16,
      lineHeight: 1.323,
    },
    hover: {
      borderColor: palette.dividerCustom.main,
    },
    focus: {
      backgroundColor: 'transparent',
      borderColor: palette.icon.lightest,
      borderWidth: '1px',
    },
    disabled: {
      borderColor: palette.dividerCustom.lightest,
      color: palette.textSecondary.lightest,
    },
    placeholder: {
      color: palette.textSecondary.dark,
      fontSize: 'inherit',
      fontFamily: 'inherit',
      opacity: 1,
    },
    smallSize: {
      paddingTop: '5.5px',
      paddingBottom: '6.5px',
      fontSize: fontSize._14,
    },
    error: {
      color: palette.error.dark,
      borderColor: palette.error.dark,
    },
    groupLabel: {
      lineHeight: 1.2,
    },
  },
  textError: {
    root: {
      color: palette.error.dark,
      fontSize: fontSize._12,
      lineHeight: 1,
      display: 'flex',
      marginTop: '2px',
      minHeight: '1em',
      '& .MuiSvgIcon-root': {
        fontSize: fontSize._12,
      },
    },
    mediumSize: {
      fontSize: fontSize._14,
      '& .MuiSvgIcon-root': {
        fontSize: fontSize._14,
      },
    },
  },
  trending: {
    up: {
      backgroundColor: palette.success.lightest,
      color: palette.success.dark,
    },
    down: {
      backgroundColor: palette.error.lightest,
      color: palette.error.dark,
    },
    flat: {
      backgroundColor: palette.dividerCustom.lightest,
      color: palette.text.dark,
    },
    smallSize: {
      fontSize: fontSize._10,
      padding: '3px',
    },
    mediumSize: {
      fontSize: fontSize._14,
      padding: '4px',
    },
    largeSize: {
      fontSize: fontSize._16,
      padding: '8px',
    },
    extraLargeSize: {
      fontSize: fontSize._20,
      padding: '10px',
    },
  },
  numberDifference: {
    up: {
      color: palette.success.dark,
    },
    down: {
      color: palette.error.dark,
    },
    flat: {
      color: palette.textSecondary.dark,
    },
    smallSize: {
      ...typographyFonts.caption,
      padding: '3px',
    },
    mediumSize: {
      ...typographyFonts.body2,
      padding: '4px',
    },
    largeSize: {
      ...typographyFonts.body1,
      padding: '8px',
    },
  },
  datePicker: {
    root: {
      borderColor: palette.primary.lightest,
    },
    calendar: {
      header: {
        root: {
          backgroundColor: palette.primary.lightest,
          border: 'none',
          borderBottom: `1px solid ${palette.primary.dark}`,
          paddingTop: '8px',
        },
        currentMonth: {
          fontWeight: fontWeight.regular,
          fontSize: fontSize._16,
          lineHeight: 1.4,
          color: palette.text.dark,
        },
        navigation: {
          button: {
            position: 'absolute',
            height: '24px',
            width: '24px',
            top: '16px',
            border: 'none',
          },
          icon: {
            position: 'absolute',
            height: '8px',
            width: '8px',
            top: '8px',
            borderStyle: 'solid',
            borderWidth: '2px 2px 0px 0px',
          },
        },
      },
      body: {
        week: {
          day: {
            width: '27px',
            lineHeight: '27px',
            fontFamily: fontFamily,
            fontWeight: fontWeight.regular,
            fontSize: fontSize._12,
            color: palette.text.dark,
          },
        },
        month: {
          day: {
            root: {
              width: '27px',
              height: '27px',
              lineHeight: 'normal',
              fontFamily: fontFamily,
              fontWeight: fontWeight.regular,
              fontSize: fontSize._12,
              color: palette.text.dark,
            },
            today: {
              boxSizing: 'border-box',
              border: `1px solid ${palette.primary.dark}`,
              fontWeight: fontWeight.bold,
            },
            hover: {
              backgroundColor: palette.dividerCustom.lightest,
            },
            selected: {
              color: palette.text.dark,
              backgroundColor: palette.primary.dark,
            },
            outsideMonth: {
              color: palette.textSecondary.dark,
            },
            disabled: {
              color: palette.textSecondary.main,
            },
          },
        },
      },
    },
  },
  datePickerTable: {
    dateContainer: {
      width: '140px',
    },
    dateTextField: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.primary.dark,
        borderRadius: 0,
      },
    },
  },
  tableTextField: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.primary.dark} !important`,
      borderRadius: 0,
    },
  },
  navMenuList: {
    item: {
      content: {
        minHeight: '60px !important',
        padding: '11px 16px',
      },
      icon: {
        minWidth: '36px',
        color: palette.text.dark,
      },
      selected: {
        text: {
          fontWeight: fontWeight.bold,
        },
      },
    },
    accordion: {
      nestedItem: {
        root: {
          paddingLeft: '56px',
        },
        selected: {
          backgroundColor: palette.primary.lightest,
        },
      },
      selected: {
        root: {
          backgroundColor: palette.primary.lightest,
        },
        nestedItem: {
          hover: {
            backgroundColor: palette.background.main,
          },
        },
      },
    },
  },
};
