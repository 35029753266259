export const EDIT_PERSONAL_DETAILS_FORM = 'EDIT_PERSONAL_DETAILS_FORM';

export const EDIT_PERSONAL_DETAILS_MODAL = 'EDIT_PERSONAL_DETAILS_MODAL';

export const USER_LIVE_ACCOUNTS_FORM = 'USER_LIVE_ACCOUNTS_FORM';
export const USER_DEMO_ACCOUNTS_FORM = 'USER_DEMO_ACCOUNTS_FORM';
export const USER_REBATE_ACCOUNTS_FORM = 'USER_REBATE_ACCOUNTS_FORM';
export const USER_REJECTED_ACCOUNTS_FORM = 'USER_REJECTED_ACCOUNTS_FORM';

export const MIGRATE_USER_MODAL = 'MIGRATE_USER_MODAL';
export const MIGRATE_USER_FORM = 'MIGRATE_USER_FORM';

export const PAYMENT_SETTINGS_MODAL = 'PAYMENT_SETTINGS_MODAL';
export const PAYMENT_SETTINGS_FORM = 'PAYMENT_SETTINGS_FORM';

export const MIGRATION_DESTINATIONS_VALUES_GP = [
  {
    value: 'GLOBAL_PRIME',
    label: 'Global Prime',
  },
  {
    value: 'GLOBAL_PRIME_X',
    label: 'Global Prime X',
  },
];

export const MIGRATION_DESTINATIONS_VALUES_GPX = [
  {
    value: 'GLOBAL_PRIME',
    label: 'Global Prime',
  },
];

export const CREATE_ACCOUNT_ADMIN_MODAL = 'CREATE_ACCOUNT_ADMIN_MODAL';
export const CREATE_ACCOUNT_ADMIN_FORM = 'CREATE_ACCOUNT_ADMIN_FORM';

export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL';

export const USER_DETAILS_BLOCK_USER_MODAL = 'USER_DETAILS_BLOCK_USER_MODAL';

export const DEFAULT_PAYMENT_SETTINGS = ['bank'];
export const EDIT_ACCOUNT_MODAL = 'EDIT_ACCOUNT_MODAL';
export const EDIT_ACCOUNT_FORM = 'EDIT_ACCOUNT_FORM';

export const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL';

export const CREATE_AFFILIATE_FORM = 'CREATE_AFFILIATE_FORM';

export const CREATE_OPERATION_SUBSCRIPTION_FORM = 'CREATE_OPERATION_SUBSCRIPTION_FORM';

export const USER_EMAIL_HISTORY_FORM = 'USER_EMAIL_HISTORY_FORM';

export const USER_PAYMENT_HISTORY_FORM = 'USER_PAYMENT_HISTORY_FORM';

export const AFFILIATE_TRANSFER_FORM = 'AFFILIATE_TRANSFER_FORM';

export const USER_MANAGEMENT_ADD_DEPOSIT_MODAL = 'USER_MANAGEMENT_ADD_DEPOSIT_MODAL';
export const USER_MANAGEMENT_ADD_DEPOSIT_FORM = 'USER_MANAGEMENT_ADD_DEPOSIT_FORM';
export const CONFIRM_MODAL = 'USER_MANAGEMENT_CONFIRM_MODAL';

export const DEPOSIT_METHODS = {
  bankwire: 'Bankwire',
  smartway: 'Smartway',
  rebate: 'Rebate',
  creditCard: 'CreditCard',
  manualDeposit: 'CreditCardAUD',
};
export const DEPOSIT_METHODS_VALUES = [
  { label: 'Bankwire', value: DEPOSIT_METHODS.bankwire },
  { label: 'Smartway', value: DEPOSIT_METHODS.smartway },
  { label: 'Partner Rebates', value: DEPOSIT_METHODS.rebate },
  { label: 'Credit Card', value: DEPOSIT_METHODS.creditCard },
  { label: 'Credit Card AU', value: DEPOSIT_METHODS.manualDeposit },
];

export const ACTIVITY_FEED_TYPE = {
  BOUNCE: 'bounce',
  CLICK: 'click',
  CONVERSATION: 'conversation',
  ECOMMERCE_SIGNUP: 'ecommerce_signup',
  EVENT: 'event',
  GENERIC_SIGNUP: 'generic_signup',
  LANDING_PAGE_SIGNUP: 'landing_page_signup',
  MARKETING_PERMISSION: 'marketing_permission',
  NOTE: 'note',
  OPEN: 'open',
  ORDER: 'order',
  POSTCARD_SENT: 'postcard_sent',
  SENT: 'sent',
  SIGNUP: 'signup',
  SQUATTER_SIGNUP: 'squatter_signup',
  UNSUB: 'unsub',
  WEBSITE_SIGNUP: 'website_signup',
  SURVEY_RESPONSE: 'survey_response',
};

export const ACTIVITY_FEED_TITLE = {
  [ACTIVITY_FEED_TYPE.OPEN]: 'Opened the email',
  [ACTIVITY_FEED_TYPE.SENT]: 'Was Sent the email',
  [ACTIVITY_FEED_TYPE.GENERIC_SIGNUP]: 'Added via API',
};

export const MAILCHIMP_USER_STATUS = {
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  CLEANED: 'cleaned',
  PENDING: 'pending',
  TRANSACTIONAL: 'transactional',
  ARCHIVED: 'archived',
};

export const OPERATION_SUB_TYPES = [
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdraw', label: 'Withdraw' },
];

export const ACCOUNT_CLOSE_REASONS = [
  { value: 'ib_accounts_only', label: 'IB Accounts Only' },
  { value: 'fraud', label: 'Fraud' },
  { value: 'money_laundering', label: 'Money Laundering' },
  { value: 'arbitrage', label: 'Arbitrage' },
  { value: 'negative_balance', label: 'Negative Balance' },
  { value: 'third_party_funding', label: 'Third Party Funding' },
  { value: 'chargeback', label: 'Chargeback' },
  { value: 'gambling_block', label: 'Gambling Block' },
  { value: 'under_investigation', label: 'Under Investigation' },
  { value: 'open_trade_dispute', label: 'Open Trade Dispute' },
  { value: 'standard_account_closure', label: 'Standard Account Closure' },
  { value: 'kyc_issue', label: ' KYC Issue' },
  { value: 'permanent_ban', label: ' Permanent Ban' },
  { value: 'trading_risk', label: ' Trading Risk' },
];

export const ACCOUNT_CLOSE_RISK_FLAGS = [
  { value: 'ib_accounts_only', label: 'IB Accounts Only' },
  { value: 'fraud', label: 'Fraud' },
  { value: 'money_laundering', label: 'Money Laundering' },
  { value: 'arbitrage', label: 'Arbitrage' },
  { value: 'negative_balance', label: 'Negative Balance' },
  { value: 'third_party_funding', label: 'Third Party Funding' },
  { value: 'chargeback', label: 'Chargeback' },
  { value: 'gambling_block', label: 'Gambling Block' },
  { value: 'under_investigation', label: 'Under Investigation' },
  { value: 'open_trade_dispute', label: 'Open Trade Dispute' },
  { value: 'standard_account_closure', label: 'Standard Account Closure' },
  { value: 'kyc_issue', label: 'KYC Issue' },
  { value: 'permanent_ban', label: 'Permanent Ban' },
  { value: 'trading_risk', label: 'Trading Risk' },
];

export const CREATE_IB_MODAL = 'CREATE_IB_MODAL';

export const CREATE_IB_FORM = 'CREATE_IB_FORM';

export const CREATE_LPOA_FORM = 'CREATE_LPOA_FORM';
export const CREATE_LPOA_MODAL = 'CREATE_LPOA_MODAL';

export const ACCOUNT_PICKER_FORM = 'ACCOUNT_PICKER_FORM';

export const REMOVE_USER_MODAL = 'REMOVE_USER_MODAL';
export const SET_TEST_USER_MODAL = 'SET_TEST_USER_MODAL';
export const REMOVE_USER_FORM = 'REMOVE_USER_FORM';

export const ACCOUNT_CLOSE_MODAL = 'ACCOUNT_CLOSE_MODAL';
export const ACCOUNT_CLOSE_FORM = 'ACCOUNT_CLOSE_FORM';
export const RESTORE_ACCOUNT_MODAL = 'RESTORE_ACCOUNT_MODAL';

export const USER_TICKETS_FORM = 'USER_TICKETS_FORM';
export const CREATE_MAILCHIMP_USER_MODAL = 'CREATE_MAILCHIMP_USER_MODAL';

export const CHANGE_CLIENT_PRO_STATUS = 'CHANGE_CLIENT_PRO_STATUS';
export const SET_UNLIMITED_ADDITIONAL_ACCOUNTS = 'SET_UNLIMITED_ADDITIONAL_ACCOUNTS';
export const SET_ACCOUNT_CREATION = 'SET_ACCOUNT_CREATION';
export const SET_UNLIMITED_DEMO_ACCOUNTS = 'SET_UNLIMITED_DEMO_ACCOUNTS';
export const SET_WHOLESALE_STATUS = 'SET_WHOLESALE_STATUS';
export const SET_LEVERAGE_MODAL = 'SET_LEVERAGE_MODAL';
export const SET_MAXIMUM_LEVERAGE_MODAL = 'SET_MAXIMUM_LEVERAGE_MODAL';
export const SET_MAXIMUM_LEVERAGE_FORM = 'SET_MAXIMUM_LEVERAGE_FORM';
export const RESEND_EMAIL_MODAL = 'RESEND_EMAIL_MODAL';

export const USER_DETAILS_BLOCKED_STATUS = 'Blocked';
export const USER_ACCOUNT_STATUS = 'Closed';

export const EDIT_USER_LEI_FORM = 'EDIT_USER_LEI_FORM';
export const EDIT_USER_LEI_MODAL = 'EDIT_USER_LEI_MODAL';

export const USER_DETAILS_SET_ECP_MODAL = 'USER_DETAILS_SET_ECP_MODAL';
export const USER_DETAILS_EDIT_TRADING_STATUS_MODAL = 'USER_DETAILS_EDIT_TRADING_STATUS_MODAL';

export const USER_TRADING_STATUS = {
  DISABLED: 'Trading Disabled',
  ENABLED: 'Trading Enabled',
};

export const USER_TRADING_MESSAGE_NOTIFICATION = {
  DISABLED: 'Trading for this user has been disabled',
  ENABLED: 'Trading for this user has been enabled',
};

export const QUIZ_DETAILS_MODAL = 'QUIZ_DETAILS_MODAL';

export const QUIZ_TYPE_LABEL = {
  RETAIL: 'Retail Quiz',
  PRO: 'Pro Quiz',
  AML: 'AML Quiz',
  TMD: 'TMD Quiz',
  TMD_AGREEMENT: 'TMD (agreement)',
};

export const TMD_AGREEMENT_TYPE = 'tmd_agreement';

export const ALLOW_COPY_ACCOUNT_MODAL = 'ALLOW_COPY_ACCOUNT_MODAL';

export const USER_DETAILS_ENABLE_EMAIL_VERIFICATION_MODAL =
  'USER_DETAILS_ENABLE_EMAIL_VERIFICATION_MODAL';

export const APPROVE_ACCOUNT_MODAL = 'APPROVE_ACCOUNT_MODAL';
