import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import TPNavMenuList from 'components/TP-UI/TPNavMenuList';
import * as actionCreators from '../actions';
import { getMenuItems } from '../selectors';

class NavMenuListContainer extends Component {
  onMenuClick = () => {
    const {
      actions: { menuTabChanged },
      onMinMenuClick,
      onChange,
    } = this.props;
    menuTabChanged();
    if (this.props.minimized && onMinMenuClick) {
      onMinMenuClick();
    } else if (onChange) {
      onChange();
    }
  };

  filterMenuItemList = () => {
    const { mobile, menuItemsList } = this.props;
    return mobile ? menuItemsList.filter((item) => !item.mainMenuItem) : menuItemsList;
  };

  render() {
    const props = {
      onItemClick: this.onMenuClick,
      minimized: this.props.minimized,
      selectedRoute: this.props.location.pathname,
      menuItems: this.filterMenuItemList(),
    };
    return <TPNavMenuList {...props} />;
  }
}

NavMenuListContainer.propTypes = {
  location: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  menuItemsList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    menuItemsList: getMenuItems(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavMenuListContainer));
