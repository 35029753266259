import last from 'lodash/last';

export const upperCase = (value) => value && value.toUpperCase();

export const cardDate = (value, previousValue) => {
  if (value.length > 5) {
    return;
  }

  const lastChar = last(value);
  if (value.length === 2 && previousValue.length === 1) {
    value += '/';
  } else if ((lastChar < '0' || lastChar > '9') && lastChar !== '/') {
    return previousValue;
  }

  return value;
};

export const numberMaxLength = (maxLength) => {
  return (value, previousValue) => {
    if (value.length > maxLength) {
      return;
    }

    const lastChar = last(value);
    if (lastChar < '0' || lastChar > '9') {
      return previousValue;
    }

    return value;
  };
};

export const number = (value, previousValue) => {
  const lastChar = last(value);
  if (lastChar < '0' || lastChar > '9') {
    return previousValue;
  }
  return value;
};

export const amount = (value, previousValue) => {
  const price = /^\d*([.](\d{0,6}))?$/;
  if (!price.test(value)) {
    return previousValue;
  }
  return value;
};

export const lettersWithSymbols = (value, previousValue) => {
  const letters = /^[a-zA-Z]*([-' .][a-zA-Z]*)*$/;
  if (!letters.test(value)) {
    return previousValue;
  }
  return value;
};

export const lettersWithSymbolsAndNumbers = (value, previousValue) => {
  const letters = /^[a-zA-Z0-9]*([-' .][a-zA-Z0-9]*)*$/;
  if (!letters.test(value)) {
    return previousValue;
  }
  return value;
};

export const normalizeDate = (value, previousValue) => {
  let parts = value.split('/');
  if (!value) {
    return '';
  }

  if (parts.length > 3) {
    return previousValue;
  }

  if (!value || !previousValue || value.length > previousValue.length) {
    for (let i = 0; i < parts.length; i++) {
      if (i < 2 && parts[i].length > 2) {
        return previousValue;
      }

      if (i === 2 && parts[i].length > 4) {
        return previousValue;
      }
    }
  }

  if (
    parts.length < 3 &&
    parts[parts.length - 1].length === 2 &&
    previousValue.replace(value, '') !== '/'
  ) {
    parts[parts.length - 1] += '/';
  }

  return parts.join('/');
};

export const phoneNumber = (value, previousValue) => {
  const letters = /^\+{1}([0-9])*$/;
  if (!letters.test(value)) {
    return previousValue;
  }
  return value;
};

export const zipCode = (value, previousValue) => {
  const letters = /^[0-9A-Za-z]*([ -][0-9A-Za-z]*)*$/;
  if (!letters.test(value)) {
    return previousValue;
  }
  return upperCase(value);
};

export const noSpecialSymbolsToUpper = (value, previousValue) => {
  const letters = /^([a-zA-Z]*[0-9]*)*$/;
  if (!letters.test(value)) {
    return upperCase(previousValue);
  }
  return upperCase(value);
};
