export const APPROVE_WITHDRAW_MODAL = 'APPROVE_WITHDRAW_MODAL';
export const DECLINE_WITHDRAW_MODAL = 'DECLINE_WITHDRAW_MODAL';
export const PARTNER_TRANSFER_AUTO_MODAL = 'PARTNER_TRANSFER_AUTO_MODAL';
export const DECLINE_WITHDRAW_FORM = 'DECLINE_WITHDRAW_FORM';
export const APPROVE_SMARTWAY_MODAL = 'APPROVE_SMARTWAY_MODAL';
export const DECLINE_SMARTWAY_MODAL = 'DECLINE_SMARTWAY_MODAL';
export const DECLINE_SMARTWAY_FORM = 'DECLINE_SMARTWAY_FORM';
export const NEW_DEPOSIT_FORM = 'NEW_DEPOSIT_FORM';
export const ADD_DEPOSIT_MODAL = 'ADD_DEPOSIT_MODAL';
export const CONFIRM_MODAL = 'CONFIRM_MODAL';
export const UPDATE_DEPOSIT_MODAL = 'UPDATE_DEPOSIT_MODAL';
export const UPDATE_DEPOSIT_FORM = 'UPDATE_DEPOSIT_FORM';
export const CONFIRM_UPDATE_MODAL = 'CONFIRM_UPDATE_MODAL';
export const PROCESS_WITHDRAW_MODAL = 'PROCESS_WITHDRAW_MODAL';
export const PROCESS_WITHDRAW_FORM = 'PROCESS_WITHDRAW_FORM';
export const ALREADY_PROCESSING_MODAL = 'ALREADY_PROCESSING_MODAL';
export const ALREADY_APPROVAL_MODAL = 'ALREADY_APPROVAL_MODAL';
export const MANUAL_APPROVE_WITHDRAW_MODAL = 'MANUAL_APPROVE_WITHDRAW_MODAL';

export const PAYMENT_METHODS = [
  {
    label: 'Withdraw',
    value: 'withdraw',
  },
  {
    label: 'Deposit',
    value: 'deposit',
  },
];

export const DEPOSIT_METHODS = {
  bankwire: 'Bankwire',
  smartway: 'Smartway',
  rebate: 'Rebate',
  creditCard: 'CreditCard',
  manualDeposit: 'CreditCardAUD',
};

export const DEPOSIT_METHODS_VALUES = [
  { label: 'Bankwire', value: DEPOSIT_METHODS.bankwire },
  { label: 'Smartway', value: DEPOSIT_METHODS.smartway },
  { label: 'Partner Rebates', value: DEPOSIT_METHODS.rebate },
  { label: 'Credit Card', value: DEPOSIT_METHODS.creditCard },
  { label: 'Credit Card AU', value: DEPOSIT_METHODS.manualDeposit },
];

export const PSP_OPTIONS_FORM = 'PSP_OPTIONS_FORM';
export const PSP_OPTIONS_MODAL = 'PSP_OPTIONS_MODAL';

export const DEPOSITS_HISTORY_FORM = 'DEPOSITS_HISTORY_FORM';
export const WITHDRAWALS_HISTORY_FORM = 'WITHDRAWALS_HISTORY_FORM';
