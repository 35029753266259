import api from 'modules/api';

// export const getVerificationRequest = (payload) => {
//   return api.get('/verification?t=' + new Date().getTime(), payload);
// };

export const getPendingVerificationsRequest = (payload) => {
  return api.get('/verification/pending', { params: payload });
};

export const changeVerificationStatusRequest = (payload) => {
  return api.post('/verification/change-status', payload);
};

export const approveUserRequest = (payload) => {
  return api.post('/verification/change-status', payload);
};

export const loginSinceRejectionRequest = (payload) => {
  return api.patch('/verification/login-since-rejection', payload);
};

export const getKYCPendingRequest = (payload) => {
  return api.get('/verification/kyc-pending', { params: payload });
};
export const getKYCPendingCountRequest = () => {
  return api.get('/verification/kyc-count');
};

export const getKYCPendingFollowUpRequest = (payload) => {
  return api.get('/verification/kyc-follow-up', { params: payload });
};
export const getKYCPendingFollowUpCountRequest = () => {
  return api.get('/verification/kyc-follow-up-count');
};
export const setKYCPendingRequest = ({ _id, validationType, isPending }) => {
  return api.post('/verification/kyc-set-pending', {
    _id,
    validationType,
    isPending,
  });
};
