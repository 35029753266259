import React, { useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';

import TPGrid from 'components/TP-UI/TPGrid';
import TPPageHeader from 'components/TP-UI/TPPageHeader';
import TPImage from 'components/TP-UI/TPImage';
import TPTypography from 'components/TP-UI/TPTypography';
import HeaderButtons from 'modules/common/HeaderButtons';
import ActionsContent from './components/ActionsContent';
import config from 'config';
import { Routes } from 'constants/routeConstants';

const { LOGO, CURRENT_HUB_LABEL } = config;

const Headers = () => {
  const mobile = isMobileOnly;
  const buttonGroupProps = useMemo(
    () => [
      {
        label: 'Primary',
        primary: true,
      },
      {
        label: 'Alternative',
        alternative: true,
      },
    ],
    [],
  );

  return (
    <TPGrid container spacing={1}>
      <TPGrid item container xs={12}>
        <TPTypography>Default (reload page as mobile device for mobile view test)</TPTypography>
      </TPGrid>
      <TPGrid item container xs={12} alignItems="center">
        <TPPageHeader
          minimized={mobile}
          title="Header"
          actions={mobile ? null : <ActionsContent />}>
          <HeaderButtons items={buttonGroupProps} />
        </TPPageHeader>
      </TPGrid>
      <TPGrid item container xs={12} alignItems="center">
        <TPPageHeader
          minimized={true}
          title="Header"
          breadcrumbsRoutes={[
            { label: 'Payments', path: Routes.PAYMENTS },
            { label: 'Account', path: Routes.ACCOUNTS },
            { label: 'Home', path: Routes.HOME },
          ]}
          actions={mobile ? null : <ActionsContent hideCalendar />}>
          <HeaderButtons items={buttonGroupProps} />
        </TPPageHeader>
      </TPGrid>
      <TPGrid item container xs={12}>
        <TPTypography>Drawer</TPTypography>
      </TPGrid>
      <TPGrid item container xs={12} alignItems="center">
        <TPPageHeader
          minimized
          title={<TPImage alt={CURRENT_HUB_LABEL} src={LOGO.blue} className={{ height: '48px' }} />}
          actions={<ActionsContent />}></TPPageHeader>
      </TPGrid>
    </TPGrid>
  );
};

export default Headers;
