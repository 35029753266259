const styles = (theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div:nth-child(3) > div': {
      borderRadius: 0,
    },
    '& .MuiPaper-root': {
      overflow: 'unset',
    },
  },
  paperFullScreen: {
    width: '100vw !important',
    height: '100%',
    maxHeight: '100% !important',
  },
  paper: {
    width: '288px',
    backgroundColor: theme.contentLayout.backgroundColor,
    boxShadow: '0 2px 6px 2px rgba(0,0,0,0.04)',
    outline: 'none',
    padding: '32px',
    '@media (min-width:768px)': {
      width: '529px',
    },
    maxHeight: '95%',
    overflow: 'auto',
  },
  container: {},
  closeButton: {
    color: theme.palette.greyBlue,
    padding: 0,
    marginLeft: '8px !important',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '24px',
    gap: '10px',
  },
  titleContainer: {
    width: '100%',
  },
  title: {
    color: theme.palette.text.main,
    fontSize: '26px',
    fontWeight: 400,
    lineHeight: '36px',
    '@media (min-width:768px)': {
      lineHeight: '34px',
    },
  },
  titleEndAdornment: {
    position: 'relative',
    bottom: '-2px',
    display: 'inline',
    paddingLeft: '8px',
  },
  infoText: {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
  },
  submitButton: {
    padding: '17px 50px',
    borderRadius: 0,
  },
});

export default styles;
