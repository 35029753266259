import React, { memo, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import mergeUrlPaths from 'utils/mergeUrlPaths';

const NavTabsContent = ({ currentBaseUrl, tabsData, defaultPath, tabContentProps }) => {
  const tabsRouting = useMemo(() => {
    const redirectPath = mergeUrlPaths([currentBaseUrl, defaultPath], true);
    const combinedTabsData = tabsData.flatMap((curr) => {
      const mergedUrl = mergeUrlPaths([currentBaseUrl, curr.path]);
      return [{ ...curr, mergedUrl }];
    });
    return [combinedTabsData, redirectPath];
  }, [currentBaseUrl, defaultPath, tabsData]);

  const [tabs, redirectPath] = tabsRouting;

  return (
    <Switch>
      {tabs.map(({ component: Component, mergedUrl, children, contentProps }) => {
        return (
          <Route path={mergedUrl} exact key={mergedUrl}>
            {Component ? <Component {...tabContentProps} {...contentProps} /> : children}
          </Route>
        );
      })}
      <Route path="*">
        <Redirect to={redirectPath} />
      </Route>
    </Switch>
  );
};

export default memo(NavTabsContent);
