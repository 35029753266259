import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { authenticationFail, cleanupAuth, logout } from '../actions';
import { push } from 'modules/reduxNavigation/actions';
import { getLocation } from 'modules/reduxNavigation/selectors';
import { Routes } from 'constants/routeConstants';
import { getReduxForms } from 'components/App/selectors';
import { reset } from 'redux-form';
import { LOGOUT_REASON } from '../constants';
import { getIsAuthenticated } from '../selectors';

function removeLocalStorageAdminCredentials() {
  localStorage.removeItem('adminId');
  localStorage.removeItem('adminToken');
  localStorage.removeItem('adminRefreshToken');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
}

function* resetReduxForms() {
  const forms = yield select(getReduxForms);

  for (let key of Object.keys(forms)) {
    yield put(reset(key));
  }
}

function* handleAuthenticationFail() {
  const isAuthenticated = yield select(getIsAuthenticated);
  if (isAuthenticated) {
    yield put(logout({ reason: LOGOUT_REASON.AUTH_FAIL }));
  }
}

function* logoutHandler(action) {
  const { reason } = action.payload || {};
  yield call(resetReduxForms);
  removeLocalStorageAdminCredentials();
  const location = yield select(getLocation);

  if (location.pathname.indexOf(Routes.SIGNIN) === -1) {
    yield put(push(Routes.SIGNIN, { reason }));
  }

  //need to clean up all data that is related to auth
  yield put(cleanupAuth());
}

export default function* watchRequest() {
  yield all([
    takeEvery(logout, logoutHandler),
    takeLatest(authenticationFail, handleAuthenticationFail),
  ]);
}
