import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQueue } from '../selectors';
import { cleanup, locationChange } from '../actions';

const ReduxNavigationContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const queue = useSelector(getQueue);

  useEffect(() => {
    dispatch(locationChange(location));
  }, [dispatch, location]);

  useEffect(() => {
    const action = queue && queue[0];
    if (action) {
      history[action.method].apply(history, [...action.payload]);
      dispatch(cleanup(action));
    }
  }, [queue, dispatch, history]);

  return null;
};

export default ReduxNavigationContainer;
