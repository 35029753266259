import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { redirectToDashboard } from '../../SignIn/sagas';
import { reset, stopSubmit } from 'redux-form';
import { MFA_ENABLE_VERIFICATION_FORM } from 'modules/auth/components/MfaPanel/constants';
import { setupMfaFail, setupMfaSuccess } from '../../../components/MfaPanel/actions';
import { getUserId } from '../../../selectors';
import { getUpdatedUserRequest } from '../../../../menu/actions';
import * as notificationsActions from 'modules/notifications/actions';

function* handleSetupMfaFail() {
  yield put(
    stopSubmit(MFA_ENABLE_VERIFICATION_FORM, {
      _error: { key: 'backend:mfaSetupError' },
    }),
  );
}

function* handleSetupSuccess(action) {
  if (action.response.data.enabled) {
    const userId = yield select(getUserId);
    yield put(getUpdatedUserRequest(userId));
    yield put(
      notificationsActions.showNotificationInfo({
        key: 'auth:mfa.notifications.enable.success',
      }),
    );
    yield put(reset(MFA_ENABLE_VERIFICATION_FORM));
    yield call(redirectToDashboard);
  }
}

export default function* watchRequest() {
  yield all([
    takeEvery(setupMfaSuccess, handleSetupSuccess),
    takeEvery(setupMfaFail, handleSetupMfaFail),
  ]);
}
