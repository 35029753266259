import React from 'react';
import { compose } from 'redux';
import { Form, Field, reduxForm } from 'redux-form';
import { Typography, Grid, withStyles } from '@material-ui/core';

import Picker from 'modules/common/Picker';
import PrimaryButton from 'modules/common/PrimaryButton';
import TPDatePicker from 'components/TP-UI/TPDatePicker';

import styles from './styles';

const MarketingDataExport = ({ classes, handleSubmit, sources, isPending }) => {
  const sourcesValues = sources.map((source) => ({
    value: source,
    label: source,
  }));

  return (
    <Form onSubmit={handleSubmit}>
      <Typography className={classes.title}>Export data</Typography>

      <Grid item xs={12}>
        <Field
          name="dateFrom"
          id="dateFrom"
          component={TPDatePicker}
          showStartAdornment={false}
          placeholder="Date From"
          label="Date From"
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name="dateTo"
          id="dateTo"
          component={TPDatePicker}
          showStartAdornment={false}
          placeholder="Date To"
          label="Date To"
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name="source"
          component={Picker}
          label="Source"
          values={sourcesValues}
          placeholder="Source"
        />
      </Grid>

      <div className={classes.buttonsSection}>
        <PrimaryButton
          color="primary"
          size="large"
          label="Export"
          isPending={isPending}
          type="submit"
        />
      </div>
    </Form>
  );
};

export default compose(reduxForm(), withStyles(styles))(MarketingDataExport);
