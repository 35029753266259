import api from 'modules/api';

// export const getVerificationRequest = (payload) => {
//   return api.get('/verification?t=' + new Date().getTime(), payload);
// };

export const getPendingAccountsRequest = (payload) => {
  return api.get('/account/pending', { params: payload });
};

export const approveAccountsRequest = (payload) => {
  return api.post('/account/approve', {
    _id: payload._id,
  });
};

export const declineAccountsRequest = (payload) => {
  return api.post('/account/decline', payload);
};
