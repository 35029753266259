import isElInViewport from './isElInViewport';

// redux supports subforms, so errors for such forms are returned as nested objects
// example
// { profileDetails : { firstName: { key: 'common.required' } } }
// and set id for form components like 'profileDetails.firstName'
const getFirstError = (errors, key = []) => {
  const firstKey = Object.keys(errors)[0];
  return typeof errors[firstKey] === 'string' || errors[firstKey].key || errors[firstKey]._error
    ? [...key, firstKey].join('.')
    : getFirstError(errors[firstKey], [...key, firstKey]);
};

const scrollIntoFirstFormError = (errors) => {
  const firstError = getFirstError(errors);
  const el =
    document.querySelector(`[id="${firstError}"]`) ||
    document.querySelector(`[name="${firstError}"]`);
  const isInViewport = isElInViewport(el);
  if (el && !isInViewport && el.scrollIntoView) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};
export default scrollIntoFirstFormError;
