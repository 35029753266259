import { handleActions } from 'redux-actions';
import { updateBasename, updateHub } from '../actions';

const defaultState = {
  basename: '',
  hub: '',
};

const basenameReducer = handleActions(
  {
    [updateBasename]: (state, { payload }) => {
      return {
        ...state,
        basename: payload,
      };
    },
    [updateHub]: (state, { payload }) => {
      return {
        ...state,
        hub: payload,
      };
    },
  },
  defaultState,
);

export default basenameReducer;
