import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import React, { useEffect, useRef } from 'react';
import isElInViewport from 'utils/isElInViewport';

const FormError = ({ error }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && ref.current.scrollIntoView) {
      const isInViewport = isElInViewport(ref.current);
      if (!isInViewport) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [ref, error]);

  return <TPErrorMessage ref={ref} error={error} size="medium" />;
};

export default FormError;
