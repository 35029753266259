import { createAction } from 'redux-actions';

export const PUSH = 'PUSH';
export const REPLACE = 'REPLACE';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const CLEAN_ROUTE_ACTION = 'CLEAN_ROUTE_ACTION';

const payloadCreator = (payload, ...args) => {
  return [payload, ...args];
};

export const push = createAction(PUSH, payloadCreator);
export const replace = createAction(REPLACE, payloadCreator);

export const locationChange = createAction(LOCATION_CHANGE);

export const cleanup = createAction(CLEAN_ROUTE_ACTION);
