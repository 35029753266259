import { combineActions, handleActions } from 'redux-actions';

import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';
import { cleanupAuth } from '../actions';
const defaultState = false;

export default handleActions(
  {
    [combineActions(userActionCreators.signinByEmailSuccess)](_, action) {
      return action.response.data.isImpersonation;
    },
    [cleanupAuth]: () => {
      return defaultState;
    },
  },
  defaultState,
);
