import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { postponeSetupMfaSuccess, postponeSetupMfaFail, skipMfaSetup } from '../actions';
import { redirectToDashboard } from '../../../pages/SignIn/sagas';
import { MFA_SETUP_LATER_FORM, MFA_SETUP_LATER_MODAL } from '../constants';
import { closeModal } from 'modules/common/CustomModal/actions';
import { getUserId } from '../../../selectors';
import { getUpdatedUserRequest } from '../../../../menu/actions';
import { stopSubmit } from 'redux-form';

function* handleFail(action) {
  yield put(
    stopSubmit(MFA_SETUP_LATER_FORM, {
      _error: action?.error?.data?.message,
    }),
  );
}

function* handlePostponeSetupMfaSuccess() {
  yield put(closeModal(MFA_SETUP_LATER_MODAL));
  const userId = yield select(getUserId);
  yield put(getUpdatedUserRequest(userId));
  yield call(redirectToDashboard);
}

export default function* watchRequest() {
  yield all([
    takeEvery(skipMfaSetup, redirectToDashboard),
    takeEvery(postponeSetupMfaSuccess, handlePostponeSetupMfaSuccess),
    takeEvery(postponeSetupMfaFail, handleFail),
  ]);
}
