import React, { useMemo, useState } from 'react';
import BottomBar from '@material-ui/core/BottomNavigation';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import menuIcon from 'static/ico_menu.svg';
import { getTranslation } from 'utils/compositeTranslationHandler';
import BottomNavigationAction from '../BottomNavigationAction';
import BottomNavigationMenuAction from '../BottomNavigationMenuAction';
import styles from './styles';
import { useSelector } from 'react-redux';
import { getMenuItems } from '../../selectors';

const BottomNavigation = ({ classes, location }) => {
  const { t } = useTranslation();
  const menuItemsList = useSelector(getMenuItems);
  const mainMenuItems = useMemo(() => {
    return menuItemsList.filter((item) => item.mainMenuItem);
  }, [menuItemsList]);
  const subMenuItems = useMemo(() => {
    return menuItemsList.filter((item) => !item.mainMenuItem);
  }, [menuItemsList]);

  const selectedItem = useMemo(() => {
    const index = mainMenuItems.findIndex((item) => {
      return (
        (item.path && location.pathname.includes(item.path)) ||
        (item.items && item.items.some((i) => location.pathname.includes(i.path)))
      );
    });
    const lastMenuIndex = mainMenuItems.length;
    return index >= 0 ? index : lastMenuIndex;
  }, [location.pathname, mainMenuItems]);

  const [value, setValue] = useState(selectedItem);

  return (
    <BottomBar
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}>
      {mainMenuItems.map((item) => (
        <BottomNavigationAction
          id={item.id}
          key={item.id}
          label={getTranslation(t, item.label)}
          icon={item.iconComponent}
          subItems={get(item, 'menuItemsList', []).filter((item) => item.visible)}
          url={item.path}
        />
      ))}
      <BottomNavigationMenuAction
        label={t('menu:navigation.menu')}
        icon={menuIcon}
        subItems={subMenuItems}
      />
    </BottomBar>
  );
};

export default withRouter(withStyles(styles)(BottomNavigation));
