import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import MainLayout from 'modules/common/MainLayout';
import NotFoundPageContent from '../NotFoundPageContent';

const NotFoundPage = ({ classes, userAuthenticated, onRedirectToHome, buttonLabel }) => {
  const { t } = useTranslation('notFoundPage');
  return userAuthenticated ? (
    <MainLayout
      title={t('title')}
      contentClassName={classes.mainLayoutContainer}
      headerClassName={classes.headerTitle}>
      <DocumentTitle title={t('title')} />
      <NotFoundPageContent onRedirectToHome={onRedirectToHome} buttonLabel={buttonLabel} />
    </MainLayout>
  ) : (
    <div className={classes.notFoundPageContentContainer}>
      <NotFoundPageContent onRedirectToHome={onRedirectToHome} buttonLabel={buttonLabel} />
    </div>
  );
};

export default withStyles(styles)(NotFoundPage);
