import get from 'lodash/get';
import { stopSubmit } from 'redux-form';
import { all, put, takeEvery } from 'redux-saga/effects';
import { validateMfaFail, validateMfaSuccess } from '../actions';
import { MFA_INPUT_FORM } from '../constants';
import { redirectToDashboard } from '../../SignIn/sagas';

function* showError(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(MFA_INPUT_FORM, {
      _error: errorMessage,
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(validateMfaSuccess, redirectToDashboard),
    takeEvery(validateMfaFail, showError),
  ]);
}
