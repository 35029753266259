export default (theme) => ({
  title: {
    color: theme.palette.text.main,
    fontSize: 26,
    fontWeight: 600,
    marginBottom: 25,
  },
  buttonsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
