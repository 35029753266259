import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'components/App';
import { getMfaQrCodeRequest, setupMfaRequest } from 'modules/auth/components/MfaPanel/actions';
import { reset } from 'redux-form';
import { MFA_ENABLE_VERIFICATION_FORM } from 'modules/auth/components/MfaPanel/constants';
import { getSecret, getQrCodeImage } from 'modules/auth/components/MfaPanel/selectors';
import { createPendingSelector } from 'modules/api/selectors';
import saga from '../sagas';
import MfaSetup from '../../../components/MfaSetup';

const MfaSetupContainer = () => {
  const dispatch = useDispatch();
  const qrCode = useSelector(getQrCodeImage);
  const secret = useSelector(getSecret);
  const setupPending = useSelector(createPendingSelector(setupMfaRequest));
  const loading = useSelector(createPendingSelector(getMfaQrCodeRequest));

  useEffect(() => {
    store.injectSaga('mfaSetupSaga', saga);

    return () => {
      store.injectSaga('mfaSetupSaga', null);
    };
  }, []);

  useEffect(() => {
    dispatch(getMfaQrCodeRequest());

    return () => {
      dispatch(reset(MFA_ENABLE_VERIFICATION_FORM));
    };
  }, [dispatch]);

  const handleSubmit = useCallback(
    ({ code }) => {
      dispatch(setupMfaRequest({ code, secret }));
    },
    [dispatch, secret],
  );

  return (
    <MfaSetup
      required={false}
      loading={loading}
      pending={setupPending}
      onSubmit={handleSubmit}
      qrCode={qrCode}
      secret={secret}
    />
  );
};

export default MfaSetupContainer;
