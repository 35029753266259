import { combineActions, handleActions } from 'redux-actions';

import * as mfaActionCreators from '../pages/MfaInput/actions';
import { setupMfaRequiredSuccess } from '../pages/MfaRequired/actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(mfaActionCreators.validateMfaSuccess, setupMfaRequiredSuccess)](state, action) {
      return action.response.data.hideMfaToken || null;
    },
  },
  defaultState,
);
