import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PrivateRoute from '../component/PrivateRoute';

import {
  getIsAuthenticated,
  getIsAdminRole,
  getIsManagerRole,
  getUserRole,
} from 'modules/auth/selectors';
import { Roles } from 'constants/userRoles';
import { MODULES_PERMISSION_KEY } from 'constants/permissions';

const PrivateRouteContainer = (props) => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  //TODO TP: check do we need to send info to route
  const role = useSelector(getUserRole);
  const isAdmin = useSelector(getIsAdminRole);
  const isManager = useSelector(getIsManagerRole);

  return (
    <PrivateRoute
      isAuthenticated={isAuthenticated}
      role={role}
      isAdmin={isAdmin}
      isManager={isManager}
      {...props}
    />
  );
};

PrivateRouteContainer.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
  isPrivate: PropTypes.bool,
  exact: PropTypes.bool,
  access: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Roles))),
  personalPermission: PropTypes.oneOf(Object.values(MODULES_PERMISSION_KEY)),
  visible: PropTypes.bool,
};

export default PrivateRouteContainer;
