import { combineActions, handleActions } from 'redux-actions';

import * as signUpActionCreators from '../pages/SignUp/actions';
import * as signInActionCreators from '../pages/SignIn/actions';
import * as actionCreators from '../actions';
import * as verificationActionCreators from 'modules/profile/pages/Verification/actions';
import * as sideMenuActionCreators from 'modules/menu/actions';
import * as migrationActionCreators from '../pages/Migration/actions';
import * as leadActionCreators from 'modules/lead/actions';
import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';
import * as myAccountsActionCreators from '../../accounts/pages/MyAccounts/actions';
import * as quizV2ActionCreators from '../../profileQuiz/actions';
import * as upgradeToProActionCreators from '../../upgradeToProV2/actions';
import * as AMLActionCreators from '../../common/AML/actions';
import * as tmdActionCreators from 'modules/common/TMD/actions';
import * as evidenceUploadActionCreators from 'modules/evidenceUploadPortal/actions';
import { cleanupAuth } from '../actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(
      signUpActionCreators.signupSuccess,
      signInActionCreators.signinSuccess,
      signUpActionCreators.googleAuthSuccess,
      sideMenuActionCreators.getUpdatedUserSuccess,
      migrationActionCreators.userMigrationSuccess,
      userActionCreators.signinByEmailSuccess,
      leadActionCreators.leadUserJoinSuccess,
      myAccountsActionCreators.selectAccountPlanSuccess,
      myAccountsActionCreators.proSignSuccess,
      quizV2ActionCreators.passQuizSuccess,
      upgradeToProActionCreators.upgradeToProV2Success,
      AMLActionCreators.sendAmlAnswersSuccess,
      tmdActionCreators.sendTmdAnswersSuccess,
      evidenceUploadActionCreators.createEvidenceStatementSuccess,
      evidenceUploadActionCreators.updateEvidenceStatementSuccess,
    )](state, action) {
      return action.response.data.user;
    },
    [combineActions(signInActionCreators.signinNonfxSuccess)](state, action) {
      return action.payload.user;
    },
    [combineActions(verificationActionCreators.updatePersonalDetailsSuccess)](state) {
      return {
        ...state,
        //...action.response.data,
      };
    },
    [combineActions(verificationActionCreators.classicVerificationUpdateSuccess)](state, action) {
      return { ...state, ...action.response.data };
    },
    [combineActions(verificationActionCreators.classicVerificationSecondStepUpdateSuccess)](
      state,
      action,
    ) {
      return { ...state, ...action.response.data };
    },
    [cleanupAuth]: () => {
      return defaultState;
    },

    [combineActions(verificationActionCreators.updatePersonalDetailsSuccess)](state, action) {
      return {
        ...state,
        ...action.response.data,
      };
    },
    [combineActions(
      verificationActionCreators.settingsVerificationUpdateSuccess,
      actionCreators.loginSinceRejectionSuccess,
    )](state, action) {
      return {
        ...state,
        ...action.response.data,
      };
    },
    [actionCreators.loginSinceRejectionRequest]: (state) => {
      return {
        ...state,
        ...{ auth: { user: { verification: { firstLoginSinceRejection: false } } } },
      };
    },
    [actionCreators.updateUserPhoneNumber]: (state, action) => {
      return {
        ...state,
        ...{ profile: { ...(state.profile || {}), phoneNumber: action.payload } },
      };
    },
    [verificationActionCreators.updateUserStateSuccess]: (state, action) => {
      return {
        ...state,
        ...{ profile: { ...(state.profile || {}), state: action.payload.state } },
      };
    },
    [verificationActionCreators.updateUserAddressLineSuccess]: (state, action) => {
      return {
        ...state,
        ...{ profile: { ...(state.profile || {}), addressLine1: action.payload.address } },
      };
    },
  },
  defaultState,
);
