import { combineActions, handleActions } from 'redux-actions';

import { forcePasswordChangeSuccess } from '../pages/ForcePasswordChange/actions';
import { verifyEmailSuccess } from '../pages/EmailVerification/actions';
import { cleanupAuth } from '../actions';
import { signinNonfxSuccess, signinSuccess } from '../pages/SignIn/actions';
import { googleAuthSuccess, signupSuccess } from '../pages/SignUp/actions';
import { userMigrationSuccess } from '../pages/Migration/actions';
import { signinByEmailSuccess } from 'modules/userManagement/pages/UserDetails/actions';
import { leadUserJoinSuccess } from 'modules/lead/actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(
      signupSuccess,
      signinSuccess,
      googleAuthSuccess,
      userMigrationSuccess,
      signinByEmailSuccess,
      leadUserJoinSuccess,
      forcePasswordChangeSuccess,
      verifyEmailSuccess,
    )](state, action) {
      return action.response.data.refreshToken;
    },
    [signinNonfxSuccess]: (state, action) => {
      return action.payload.refreshToken;
    },
    [cleanupAuth]: () => {
      return defaultState;
    },
  },
  defaultState,
);
