import { createAction } from 'redux-actions';

export const loadVerificationRequestDetails = createAction('LOAD_VERIFICATION_REQUEST_DETAILS');

export const changeVerificationStatusRequest = createAction('CHANGE_VERIFICATION_STATUS_REQUEST');
export const changeVerificationStatusSuccess = createAction('CHANGE_VERIFICATION_STATUS_SUCCESS');
export const changeVerificationStatusFail = createAction('CHANGE_VERIFICATION_STATUS_FAIL');

export const getSimilarUsersRequest = createAction('GET_SIMILAR_USERS_REQUEST');
export const getSimilarUsersSuccess = createAction('GET_SIMILAR_USERS_SUCCESS');
export const getSimilarUsersFail = createAction('GET_SIMILAR_USERS_FAIL');

export const getApplicationLogRequest = createAction('GET_APPLICATION_LOG_REQUEST');
export const getApplicationLogSuccess = createAction('GET_APPLICATION_LOG_SUCCESS');
export const getApplicationLogFail = createAction('GET_APPLICATION_LOG_FAIL');

export const updateKycStatusRequest = createAction('UPDATE_KYC_STATUS_REQUEST');
export const updateKycStatusSuccess = createAction('UPDATE_KYC_STATUS_SUCCESS');
export const updateKycStatusFail = createAction('UPDATE_KYC_STATUS_FAIL');
