import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'modules/reduxNavigation/actions';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { DEFAULT_LANG, GLOBAL_PRIME, LANG_COOKIE_NAME } from 'constants/index';
import * as actionCreators from '../actions';
import { Routes } from 'constants/routeConstants';
import { Roles } from 'constants/userRoles';
import * as authActionCreators from 'modules/auth/actions';
import * as authSelectors from 'modules/auth/selectors';
import { getUserHub } from 'modules/auth/selectors';
import * as accountActions from 'modules/accounts/pages/MyAccounts/actions';
import SideMenu from '../components/SideMenu';
import * as profileActionCreators from 'modules/profile/pages/ProfileData/actions';

class SideMenuContainer extends Component {
  constructor(props) {
    super(props);
    this.isUser = this.props.userRole !== Roles.ADMIN && this.props.userRole !== Roles.MANAGER;
  }

  componentDidMount() {
    const { i18n } = this.props;

    if (!this.isUser && i18n.language !== DEFAULT_LANG) {
      i18n.changeLanguage(DEFAULT_LANG);
      Cookies.set(LANG_COOKIE_NAME, DEFAULT_LANG, { path: '/', sameSite: 'none', secure: true });
    }
  }

  onLogoutClick = () => {
    const {
      authActions: { logout },
      profileActions: { deleteUserSessionsRequest },
    } = this.props;
    deleteUserSessionsRequest();
    logout();
  };

  onGoLiveClick = () => {
    if (this.props.hub === GLOBAL_PRIME) {
      this.props.push(Routes.MY_ACCOUNTS);
    } else {
      this.props.push(Routes.PROFILE_VERIFICATION);
    }
    this.props.accountActions.proOrRetailOpen({ isGoLive: true });
  };

  render() {
    const {
      isVerified,
      isPosted,
      isNew,
      email,
      verificationProgress,
      userName,
      isPublic,
      userRole,
      userStatus,
    } = this.props;
    const props = {
      email,
      isVerified,
      isPosted,
      isNew,
      verificationProgress,
      onLogoutClick: this.onLogoutClick,
      onGoLiveClick: this.onGoLiveClick,
      isUser: this.isUser,
      userName,
      isPublic,
      userRole,
      userStatus,
    };
    return <SideMenu {...props} />;
  }
}

const mapStateToProps = (state) => ({
  userId: authSelectors.getUserId(state),
  isVerified: authSelectors.getUserIsVerified(state),
  isPosted: authSelectors.getUserIsPosted(state),
  isNew: authSelectors.getUserIsNew(state),
  email: authSelectors.getUserEmail(state),
  verificationProgress: authSelectors.getUserVerificationProgress(state),
  userName: authSelectors.getUserName(state),
  userRole: authSelectors.getUserRole(state),
  userStatus: authSelectors.getUserStatus(state),
  hub: getUserHub(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  authActions: bindActionCreators(authActionCreators, dispatch),
  profileActions: bindActionCreators(profileActionCreators, dispatch),
  push: bindActionCreators(push, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
});

SideMenuContainer.propTypes = {
  i18n: PropTypes.object.isRequired,
  userId: PropTypes.string,
  isVerified: PropTypes.bool,
  isPosted: PropTypes.bool,
  isNew: PropTypes.bool,
  email: PropTypes.string,
  verificationProgress: PropTypes.number,
  userName: PropTypes.string,
  actions: PropTypes.object.isRequired,
  authActions: PropTypes.object.isRequired,
  profileActions: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  accountActions: PropTypes.object.isRequired,
  isPublic: PropTypes.bool,
  hub: PropTypes.string.isRequired,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SideMenuContainer));
