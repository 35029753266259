import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from 'utils/compositeTranslationHandler';
import NavigationMenuListItem from '../NavigationMenuListItem';
import NavigationMenuNestedListItem from '../NavigationMenuNestedListItem';
import styles from './styles';

const NavigationMenuList = ({
  classes,
  selectedRoute,
  menuItemsList,
  onMenuClick = () => {},
  isCollapsed,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <MenuList className={classes.menuList} component="nav">
        {menuItemsList.map(({ label, id, iconComponent, path, items }) => {
          return items?.length ? (
            <NavigationMenuNestedListItem
              id={id}
              key={id}
              label={getTranslation(t, label)}
              selectedRoute={selectedRoute}
              items={items}
              icon={iconComponent}
              onClick={onMenuClick}
              isCollapsed={isCollapsed}
            />
          ) : (
            <NavigationMenuListItem
              id={id}
              key={id}
              label={getTranslation(t, label)}
              isSelected={selectedRoute.includes(path)}
              onClick={onMenuClick}
              icon={iconComponent}
              link={path}
              isCollapsed={isCollapsed}
            />
          );
        })}
      </MenuList>
    </div>
  );
};

NavigationMenuList.propTypes = {
  selectedRoute: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  menuItemsList: PropTypes.array.isRequired,
};

export default withStyles(styles)(NavigationMenuList);
