import { useEffect } from 'react';

const Page = ({ docTitle, docMeta, children }) => {
  useEffect(() => {
    document.title = docTitle || '';
    let metaDescriptionEl = document.querySelector("meta[name='description']");

    if (docMeta) {
      if (!metaDescriptionEl) {
        metaDescriptionEl = document.createElement('meta');
        metaDescriptionEl.setAttribute('name', 'description');

        document.querySelector('head').appendChild(metaDescriptionEl);
      }

      metaDescriptionEl.setAttribute('content', docMeta);
    } else if (metaDescriptionEl) {
      metaDescriptionEl.remove();
    }
  }, [docTitle, docMeta]);
  return children;
};

export default Page;
