import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';
import CloseIcon from '@mui/icons-material/Close';

import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTypography from 'components/TP-UI/TPTypography';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPBanner = ({ onClose, children, className }) => {
  return (
    <Box sx={[styles.container, className]}>
      <Box sx={styles.messageContent}>
        {typeof children === 'string' ? (
          <TPTypography variant="body1" className={styles.message}>
            {children}
          </TPTypography>
        ) : (
          <Box sx={styles.message}>{children}</Box>
        )}
      </Box>
      {onClose ? (
        <TPIconButton
          size={SIZES.XSMALL}
          aria-label="Close"
          onClick={onClose}
          className={styles.closeButton}>
          <CloseIcon sx={styles.closeIcon} />
        </TPIconButton>
      ) : null}
    </Box>
  );
};

TPBanner.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default TPBanner;
