import { handleActions } from 'redux-actions';
import { PUSH, REPLACE, CLEAN_ROUTE_ACTION, LOCATION_CHANGE } from '../actions';

const initialState = {
  queue: [],
  location: null,
};

const reduxNavigationReducer = handleActions(
  {
    [PUSH]: (state, action) => {
      return {
        ...state,
        queue: [...state.queue, { method: 'push', payload: action.payload }],
      };
    },
    [REPLACE]: (state, action) => {
      return {
        ...state,
        queue: [...state.queue, { method: 'replace', payload: action.payload }],
      };
    },
    [LOCATION_CHANGE]: (state, action) => {
      return { ...state, location: action.payload };
    },
    [CLEAN_ROUTE_ACTION]: (state, action) => {
      return {
        ...state,
        queue: state.queue.filter((q) => q !== action.payload),
      };
    },
  },
  initialState,
);

export default reduxNavigationReducer;
