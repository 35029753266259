import { GLOBAL_PRIME, GLOBAL_PRIME_X } from 'constants/index';

export const MODULES_PERMISSION_KEY = {
  VERIFICATION_MANAGEMENT: 'verificationManagement',
  PAYNENT_MANAGER: 'paymentManager',
  USER_MANAGEMENT: 'userManagement',
  DASHBOARD: 'dashboard',
  EMAIL_MANAGEMENT: 'emailManagement',
  OPERATIONS: 'operations',
  SETTINGS: 'settings',
};

export const ACTIONS_PERMISSION_KEY = {
  DOWNLOAD_USER_DETAILS: 'downloadUserDetails',
  EDIT_USER_DETAILS: 'editUserDetails',
};

export const PERMISSION_TYPE = {
  NA: 'NA',
  VIEW: 'V',
  WRITE: 'W',
};

export const ACTION_PERMISSION_TYPE = {
  AVAILABLE: 'A',
  NA: 'NA',
};

export const DEFAULT_USER_PERMISSIONS = {
  [GLOBAL_PRIME]: {
    [ACTIONS_PERMISSION_KEY.DOWNLOAD_USER_DETAILS]: ACTION_PERMISSION_TYPE.NA,
    [ACTIONS_PERMISSION_KEY.EDIT_USER_DETAILS]: ACTION_PERMISSION_TYPE.AVAILABLE,
  },
  [GLOBAL_PRIME_X]: {
    [ACTIONS_PERMISSION_KEY.DOWNLOAD_USER_DETAILS]: ACTION_PERMISSION_TYPE.NA,
    [ACTIONS_PERMISSION_KEY.EDIT_USER_DETAILS]: ACTION_PERMISSION_TYPE.AVAILABLE,
  },
};

// ToDo: test emails. Will be changed after the test
// all modules are not available except permissions in this list
export const EXCEPTION_MODULE_PERMISSIONS_USER_LIST = {
  'testingfm2024+41@gmail.com': {
    [MODULES_PERMISSION_KEY.VERIFICATION_MANAGEMENT]: PERMISSION_TYPE.WRITE,
    [MODULES_PERMISSION_KEY.USER_MANAGEMENT]: PERMISSION_TYPE.WRITE,
    [MODULES_PERMISSION_KEY.EMAIL_MANAGEMENT]: PERMISSION_TYPE.WRITE,
  },
  'testingfm2024+42@gmail.com': {
    [MODULES_PERMISSION_KEY.VERIFICATION_MANAGEMENT]: PERMISSION_TYPE.WRITE,
    [MODULES_PERMISSION_KEY.USER_MANAGEMENT]: PERMISSION_TYPE.WRITE,
  },
};

// override default permissions
export const ACTION_PERMISSIONS_USER_LIST = {
  'testingfm2024+40@gmail.com': {
    [ACTIONS_PERMISSION_KEY.DOWNLOAD_USER_DETAILS]: ACTION_PERMISSION_TYPE.AVAILABLE,
  },
  'admin@example.com': {
    [ACTIONS_PERMISSION_KEY.DOWNLOAD_USER_DETAILS]: ACTION_PERMISSION_TYPE.AVAILABLE,
    [ACTIONS_PERMISSION_KEY.EDIT_USER_DETAILS]: ACTION_PERMISSION_TYPE.AVAILABLE,
  },
};
