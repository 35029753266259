import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'components/App';
import { getMfaQrCodeRequest } from 'modules/auth/components/MfaPanel/actions';
import { reset } from 'redux-form';
import { MFA_ENABLE_VERIFICATION_FORM } from 'modules/auth/components/MfaPanel/constants';
import { getSecret, getQrCodeImage } from 'modules/auth/components/MfaPanel/selectors';
import { createPendingSelector } from 'modules/api/selectors';
import { setupMfaRequiredRequest } from '../actions';
import MfaSetup from '../../../components/MfaSetup';

const MfaRequiredContainer = () => {
  const dispatch = useDispatch();
  const qrCode = useSelector(getQrCodeImage);
  const secret = useSelector(getSecret);
  const setupPending = useSelector(createPendingSelector(setupMfaRequiredRequest));
  const loading = useSelector(createPendingSelector(getMfaQrCodeRequest));
  console.log(qrCode, secret, setupPending, loading);
  useEffect(() => {
    import('../sagas').then((saga) => {
      store.injectSaga('mfaRequiredSaga', saga.default);
    });

    return () => {
      store.injectSaga('mfaRequiredSaga', null);
    };
  }, []);

  useEffect(() => {
    dispatch(getMfaQrCodeRequest());

    return () => {
      dispatch(reset(MFA_ENABLE_VERIFICATION_FORM));
    };
  }, [dispatch]);

  const handleSubmit = useCallback(
    ({ code }) => {
      dispatch(setupMfaRequiredRequest({ code, secret }));
    },
    [dispatch, secret],
  );

  return (
    <MfaSetup
      required
      loading={loading}
      pending={setupPending}
      onSubmit={handleSubmit}
      qrCode={qrCode}
      secret={secret}
    />
  );
};

export default MfaRequiredContainer;
