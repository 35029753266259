import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { push } from 'modules/reduxNavigation/actions';

const useSetCurrentPageUrl = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const setCurrentPageUrl = useCallback(
    (pageNumber, addPath) => {
      const path = [match.path, addPath].filter(Boolean).join('');
      dispatch(
        push({
          pathname: generatePath(path, { pageNumber }),
        }),
      );
    },
    [dispatch, match.path],
  );

  return setCurrentPageUrl;
};

export default useSetCurrentPageUrl;
