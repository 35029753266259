import { push } from 'modules/reduxNavigation/actions';
import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import { reset } from 'redux-form';

import { proSignSuccess, selectAccountPlanSuccess } from '../../accounts/pages/MyAccounts/actions';
import { Routes } from '../../../constants/routeConstants';
import { startIdVerification } from '../actions';
import verificationModalNames from '../constants/verificationModalNames';
import { targetUserQuizSelector } from '../../auth/selectors';
import { classicVerificationSecondStepUpdateSuccess } from '../../profile/pages/Verification/actions';
import { ACCOUNT_PLANS } from '../../../constants/acctountPlans';
import { getKeepCurrentStep } from '../../profile/pages/Verification/selectors';
import * as notificationsActions from 'modules/notifications/actions';
import {
  PRO_QUIZ_FORM,
  PRO_QUIZ_MODAL,
  RETAIL_QUIZ_FORM,
  RETAIL_QUIZ_MODAL,
} from '../../profileQuiz/constants';
import { closeModal, openModal } from '../../common/CustomModal/actions';
import { cleanUpQuizQuestions, passQuizFail, passQuizSuccess } from '../../profileQuiz/actions';
import { setEvidenceUploadView } from '../../upgradeToProV2/actions';

function* startIdVerificationWorker() {
  yield put(push(Routes.PROFILE_VERIFICATION));
}

function* completeVerificationWorker() {
  const isKeepCurrentStep = yield select(getKeepCurrentStep);
  if (!isKeepCurrentStep) {
    yield put(openModal(verificationModalNames.VERIFICATION_AWAITING_MODAL));
  } else {
    yield put(
      notificationsActions.showNotificationInfo({
        key: 'profile:verification.notifications.dataIsSaved',
      }),
    );
  }
}

function* passQuizSuccessWorker(action) {
  const { selectedPlan } = action.response.data.user;
  const targetQuizProgress = yield select(targetUserQuizSelector);
  const activeQuizModalName =
    selectedPlan === ACCOUNT_PLANS.PRO ? PRO_QUIZ_MODAL : RETAIL_QUIZ_MODAL;
  const activeQuizForm = selectedPlan === ACCOUNT_PLANS.PRO ? PRO_QUIZ_FORM : RETAIL_QUIZ_FORM;

  yield put(closeModal(activeQuizModalName));
  yield put(reset(activeQuizForm));
  yield put(cleanUpQuizQuestions());

  if (selectedPlan === ACCOUNT_PLANS.PRO) {
    if (targetQuizProgress.isBlocked) {
      yield put(openModal(verificationModalNames.PRO_QUIZ_BLOCKED_MODAL));

      yield take(selectAccountPlanSuccess);

      return yield put(push(Routes.MY_ACCOUNTS));
    }
  }

  if (!targetQuizProgress.isPassed) {
    if (targetQuizProgress.attempts === 1) {
      return yield put(openModal(verificationModalNames.TRY_AGAIN_MODAL));
    }

    return yield put(openModal(verificationModalNames.SECOND_FAILED_MODAL));
  } else {
    yield put(closeModal(activeQuizModalName));
    return yield put(openModal(verificationModalNames.SUCCESS_QUIZ_MODAL));
  }
}

function* passQuizFailWorker(action) {
  const defaultError = {
    key: 'errorList:somethingWrong',
  };
  const errorMessage = action?.error?.data?.message ?? defaultError;
  yield put(notificationsActions.showNotificationError(errorMessage));
}

function* successProSignWorker() {
  yield put(setEvidenceUploadView(true));
}

function* startIdVerificationWatcher() {
  yield takeEvery(startIdVerification, startIdVerificationWorker);
}

function* completeVerificationWatcher() {
  yield takeEvery(classicVerificationSecondStepUpdateSuccess, completeVerificationWorker);
}

function* passQuizSuccessWatcher() {
  yield takeEvery(passQuizSuccess, passQuizSuccessWorker);
}

function* passQuizFailWatcher() {
  yield takeEvery(passQuizFail, passQuizFailWorker);
}

function* successSignWatcher() {
  yield takeEvery(proSignSuccess, successProSignWorker);
}

function* verificationModalsRunnerWatcher() {
  yield all([
    startIdVerificationWatcher(),
    completeVerificationWatcher(),
    passQuizSuccessWatcher(),
    passQuizFailWatcher(),
    successSignWatcher(),
  ]);
}

export default verificationModalsRunnerWatcher;
