import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPendingSelector } from 'modules/api/selectors';
import { store } from 'components/App';
import saga from '../sagas';
import { MFA_SETUP_LATER_FORM, MFA_SETUP_LATER_MODAL } from '../constants';
import { Routes } from 'constants/routeConstants';
import TPLink from 'components/TP-UI/TPLink';
import { useTranslation } from 'react-i18next';
import { skipMfaSetup, postponeSetupMfaRequest } from '../actions';
import { closeModal, openModal } from 'modules/common/CustomModal/actions';
import MfaSetupLaterModal from '../components/MfaSetupLaterModal';
import { change } from 'redux-form';

const MfaSetupLaterContainer = () => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const pending = useSelector(createPendingSelector(postponeSetupMfaRequest));

  const handleSubmit = useCallback(
    (data) => {
      if (data.postponed) {
        dispatch(postponeSetupMfaRequest(data));
      } else {
        dispatch(closeModal(MFA_SETUP_LATER_MODAL));
        dispatch(skipMfaSetup(data));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    store.injectSaga('MfaSetupLaterSaga', saga);

    return () => {
      store.injectSaga('MfaSetupLaterSaga', null);
    };
  }, []);

  const handleSkip = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(openModal(MFA_SETUP_LATER_MODAL));
    },
    [dispatch],
  );

  const handleCancel = useCallback(() => {
    dispatch(change(MFA_SETUP_LATER_FORM, 'postponed', true));
    dispatch(closeModal(MFA_SETUP_LATER_MODAL));
  }, [dispatch]);

  return (
    <>
      <TPLink to={Routes.HOME} onClick={handleSkip}>
        {t('mfaSetup.skip')}
      </TPLink>
      <MfaSetupLaterModal
        modalName={MFA_SETUP_LATER_MODAL}
        loading={pending}
        form={MFA_SETUP_LATER_FORM}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};

export default MfaSetupLaterContainer;
