const styles = {
  iframe: {
    width: '100%',
    height: 'calc(100vh - 230px)',
    border: 'none',
  },
  loader: {
    height: 'inherit',
    padding: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const classStyles = () => ({
  header: {
    whiteSpace: 'normal',
    textOverflow: 'unset',
  },
});

export { styles, classStyles };
