export default {
  menuItem: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    ...theme.navMenuList.item.content,
  }),
  selectedMenuItem: (theme) => ({
    ...theme.navMenuList.accordion.selected.root,
  }),
  menuItemLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: (theme) => ({
    ...theme.navMenuList.item.icon,
  }),
  label: {
    whiteSpace: 'normal',
  },
  nestedList: {
    padding: 0,
  },
  selectedNestedList: (theme) => ({
    ...theme.navMenuList.accordion.selected.root,
  }),
  nestedItem: (theme) => ({
    ...theme.navMenuList.accordion.nestedItem.root,
  }),
  nestedItemInSelectedMenu: (theme) => ({
    ...theme.navMenuList.item.selected.text,
    '&:hover': {
      ...theme.navMenuList.accordion.selected.nestedItem.hover,
    },
  }),
  selectedNestedItem: (theme) => ({
    ...theme.navMenuList.accordion.nestedItem.selected,
    '&:hover': {
      ...theme.navMenuList.accordion.nestedItem.selected,
    },
  }),
};
