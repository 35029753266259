export default {
  root: (theme) => ({
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.background.main,
    },
  }),
  selected: (theme) => ({
    ...theme.navMenuList.item.selected.text,
  }),
  contentWrapper: (theme) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    ...theme.navMenuList.item.content,
  }),
  fullWidth: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  iconContainer: (theme) => ({
    ...theme.navMenuList.item.icon,
  }),
  text: {
    whiteSpace: 'normal',
  },
};
