export const GOOGLE_AUTH_CREATE_PASSWORD_MODAL = 'GOOGLE_AUTH_CREATE_PASSWORD_MODAL';
export const GOOGLE_AUTH_CREATE_PASSWORD_FORM = 'GOOGLE_AUTH_CREATE_PASSWORD_FORM';

export const RETURN_URL_STORAGE_KEY = 'returnUrl';
export const LOGOUT_REASON = {
  AUTH_FAIL: 'AUTH_FAIL',
  SIGN_IN_CLEANUP: 'SIGN_IN_CLEANUP',
  USER_ACTION: 'USER_ACTION',
  TMD_FAIL: 'TMD_FAIL',
};
