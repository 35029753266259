import { createSelector } from 'reselect';

import { getMfaSettings } from 'modules/auth/selectors';

export const getQrCodeImage = (state) => state.auth.mfa.qrCodeImage;
export const getSecret = (state) => state.auth.mfa.secret;
export const getPanelExpanded = (state) => state.auth.mfa.expanded;

export const getMfaEnabled = createSelector(getMfaSettings, (mfaSettings) => {
  return mfaSettings.enabled === true;
});
