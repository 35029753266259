import { createAction } from 'redux-actions';

export const getUserAccountsRequest = createAction('GET_USER_ACCOUNTS_REQUEST');
export const getUserAccountsSuccess = createAction('GET_USER_ACCOUNTS_SUCCESS');
export const getUserAccountsFail = createAction('GET_USER_ACCOUNTS_FAIL');

export const getTradingHistoryRequest = createAction('GET_TRADING_HISTORY_REQUEST');
export const getTradingHistorySuccess = createAction('GET_TRADING_HISTORY_SUCCESS');
export const getTradingHistoryFail = createAction('GET_TRADING_HISTORY_FAIL');

export const approveUserRequest = createAction('APPROVE_USER_REQUEST');
export const approveUserSuccess = createAction('APPROVE_USER_SUCCESS');
export const approveUserFail = createAction('APPROVE_USER_FAIL');

export const sendMigrationEmailRequest = createAction('SEND_MIGRATION_EMAIL_REQUEST');
export const sendMigrationEmailSuccess = createAction('SEND_MIGRATION_EMAIL_SUCCESS');
export const sendMigrationEmailFail = createAction('SEND_MIGRATION_EMAIL_FAIL');

export const changePaymentSettingsRequest = createAction('CHANGE_PAYMENT_SETTINGS_REQUEST');
export const changePaymentSettingsSuccess = createAction('CHANGE_PAYMENT_SETTINGS_SUCCESS');
export const changePaymentSettingsFail = createAction('CHANGE_PAYMENT_SETTINGS_FAIL');

export const loadUserDetails = createAction('LOAD_USER_DETAILS');

export const createAccountAdminRequest = createAction('CREATE_ACCOUNT_ADMIN_REQUEST');
export const createAccountAdminSuccess = createAction('CREATE_ACCOUNT_ADMIN_SUCCESS');
export const createAccountAdminFail = createAction('CREATE_ACCOUNT_ADMIN_FAIL');

export const changePasswordAdminRequest = createAction('CHANGE_PASSWORD_ADMIN_REQUEST');
export const changePasswordAdminSuccess = createAction('CHANGE_PASSWORD_ADMIN_SUCCESS');
export const changePasswordAdminFail = createAction('CHANGE_PASSWORD_ADMIN_FAIL');

export const changeLiveAccountSettingsRequest = createAction(
  'CHANGE_LIVE_ACCOUNT_SETTINGS_REQUEST',
);
export const changeLiveAccountSettingsSuccess = createAction(
  'CHANGE_LIVE_ACCOUNT_SETTINGS_SUCCESS',
);
export const changeLiveAccountSettingsFail = createAction('CHANGE_LIVE_ACCOUNT_SETTINGS_FAIL');

export const deleteDemoAccountRequest = createAction('DELETE_DEMO_ACCOUNT_REQUEST');
export const deleteDemoAccountSuccess = createAction('DELETE_DEMO_ACCOUNT_SUCCESS');
export const deleteDemoAccountFail = createAction('DELETE_DEMO_ACCOUNT_FAIL');

export const createAffiliateModalOpen = createAction('CREATE_AFFILIATE_MODAL_OPEN');
export const createAffiliateModalClose = createAction('CREATE_AFFILIATE_MODAL_CLOSE');

export const createLpoaModalOpen = createAction('CREATE_LPOA_MODAL_OPEN');

export const createAffiliateModalRequest = createAction('CREATE_AFFILIATE_MODAL_REQUEST');
export const createAffiliateModalSuccess = createAction('CREATE_AFFILIATE_MODAL_SUCCESS');
export const createAffiliateModalFail = createAction('CREATE_AFFILIATE_MODAL_FAIL');

export const createLpoaModalRequest = createAction('CREATE_LPOA_MODAL_REQUEST');
export const createLpoaModalSuccess = createAction('CREATE_LPOA_MODAL_SUCCESS');
export const createLpoaModalFail = createAction('CREATE_LPOA_MODAL_FAIL');

export const getUserPaymentsRequest = createAction('GET_USER_PAYMENTS_REQUEST');
export const getUserPaymentsSuccess = createAction('GET_USER_PAYMENTS_SUCCESS');
export const getUserPaymentsFail = createAction('GET_USER_PAYMENTS_FAIL');

export const getUserRecentPayments = createAction('GET_USER_RECENT_PAYMENTS');

export const putUserRecentPayments = createAction('PUT_USER_RECENT_PAYMENTS');

export const getUserEmailsRequest = createAction('GET_USER_EMAILS_REQUEST');
export const getUserEmailsSuccess = createAction('GET_USER_EMAILS_SUCCESS');
export const getUserEmailsFail = createAction('GET_USER_EMAILS_FAIL');

export const lockUser = createAction('USER_DETAILS_LOCK_USER');
export const lockUserSuccess = createAction('USER_DETAILS_LOCK_USER_SUCCESS');
export const lockUserFail = createAction('USER_DETAILS_LOCK_USER_FAIL');

export const unlockUser = createAction('USER_DETAILS_UNLOCK_USER');
export const unlockUserSuccess = createAction('USER_DETAILS_UNLOCK_USER_SUCCESS');
export const unlockUserFail = createAction('USER_DETAILS_UNLOCK_USER_FAIL');

export const openTransferModal = createAction('OPEN_TRANSFER_MODAL');
export const closeTransferModal = createAction('CLOSE_TRANSFER_MODAL');

export const getAffiliateDataRequest = createAction('GET_AFFILIATE_DATA_REQUEST');
export const getAffiliateDataSuccess = createAction('GET_AFFILIATE_DATA_SUCCESS');
export const getAffiliateDataFail = createAction('GET_AFFILIATE_DATA_FAIL');

export const createCellxpertTransferRequest = createAction('CREATE_CELLXPERT_TRANSFER_REQUEST');
export const createCellxpertTransferSuccess = createAction('CREATE_CELLXPERT_TRANSFER_SUCCESS');
export const createCellxpertTransferFail = createAction('CREATE_CELLXPERT_TRANSFER_FAIL');

export const addDepositManuallyRequest = createAction('ADD_DEPOSIT_MANUALLY_REQUEST');
export const addDepositManuallySuccess = createAction('ADD_DEPOSIT_MANUALLY_SUCCESS');
export const addDepositManuallyFail = createAction('ADD_DEPOSIT_MANUALLY_FAIL');

export const addDepositRequest = createAction('ADD_DEPOSIT_REQUEST');
export const addDepositSuccess = createAction('ADD_DEPOSIT_SUCCESS');
export const addDepositFail = createAction('ADD_DEPOSIT_FAIL');

export const disableUserMfaRequest = createAction('DISABLE_USER_MFA_REQUEST');
export const disableUserMfaSuccess = createAction('DISABLE_USER_MFA_SUCCESS');
export const disableUserMfaFail = createAction('DISABLE_USER_MFA_FAIL');

export const getUserEmailFeedRequest = createAction('GET_USER_EMAIL_FEED_REQUEST');
export const getUserEmailFeedSuccess = createAction('GET_USER_EMAIL_FEED_SUCCESS');
export const getUserEmailFeedFail = createAction('GET_USER_EMAIL_FEED_FAIL');

export const getUserCycleRequest = createAction('GET_USER_CYCLE_REQUEST');
export const getUserCycleSuccess = createAction('GET_USER_CYCLE_SUCCESS');
export const getUserCycleFail = createAction('GET_USER_CYCLE_FAIL');

export const createSubscriptionModalOpen = createAction('CREATE_OP_SUBSCRIBE_MODAL_OPEN');
export const createSubscriptionModalClose = createAction('CREATE_OP_SUBSCRIBE_MODAL_CLOSE');

export const createSubscriptionModalRequest = createAction('CREATE_SUBSCRIPTION_MODAL_REQUEST');
export const createSubscriptionModalSuccess = createAction('CREATE_SUBSCRIPTION_MODAL_SUCCESS');
export const createSubscriptionModalFail = createAction('CREATE_SUBSCRIPTION_MODAL_FAIL');

export const removeSubscriptionModalRequest = createAction('REMOVE_SUBSCRIPTION_MODAL_REQUEST');
export const removeSubscriptionModalSuccess = createAction('REMOVE_SUBSCRIPTION_MODAL_SUCCESS');
export const removeSubscriptionModalFail = createAction('REMOVE_SUBSCRIPTION_MODAL_FAIL');

export const getSubscriptionModalRequest = createAction('GET_SUBSCRIPTION_MODAL_REQUEST');
export const getSubscriptionModalSuccess = createAction('GET_SUBSCRIPTION_MODAL_SUCCESS');
export const getSubscriptionModalFail = createAction('GET_SUBSCRIPTION_MODAL_FAIL');

export const setProUserRequest = createAction('SET_PRO_USER_REQUEST');
export const setProUserSuccess = createAction('SET_PRO_USER_SUCCESS');
export const setProUserFail = createAction('SET_PRO_USER_FAIL');

export const setAllowCopyAccountRequest = createAction('SET_ALLOW_COPY_ACCOUNT_REQUEST');
export const setAllowCopyAccountSuccess = createAction('SET_ALLOW_COPY_ACCOUNT_SUCCESS');
export const setAllowCopyAccountFail = createAction('SET_ALLOW_COPY_ACCOUNT_FAIL');

export const setWholesaleUserRequest = createAction('SET_WHOLESALE_USER_REQUEST');
export const setWholesaleUserSuccess = createAction('SET_WHOLESALE_USER_SUCCESS');
export const setWholesaleUserFail = createAction('SET_WHOLESALE_USER_FAIL');

export const createIBClientRequest = createAction('CREATE_I_B_CLIENT_REQUEST');
export const createIBClientSuccess = createAction('CREATE_I_B_CLIENT_SUCCESS');
export const createIBClientFail = createAction('CREATE_I_B_CLIENT_FAIL');

export const openCreateIBClientModal = createAction('OPEN_CREATE_I_B_CLIENT_MODAL');
export const closeCreateIBClientModal = createAction('CLOSE_CREATE_I_B_CLIENT_MODAL');

export const signinByEmailRequest = createAction('SIGNIN_BY_EMAIL_REQUEST');
export const signinByEmailSuccess = createAction('SIGNIN_BY_EMAIL_SUCCESS');
export const signinByEmailFail = createAction('SIGNIN_BY_EMAIL_FAIL');

export const getPaymentCommentsRequest = createAction('GET_PAYMENT_COMMENTS_REQUEST');
export const getPaymentCommentsSuccess = createAction('GET_PAYMENT_COMMENTS_SUCCESS');
export const getDepositCommentsFail = createAction('GET_PAYMENT_COMMENTS_FAIL');

export const getQuizCommentsRequest = createAction('GET_QUIZ_COMMENTS_REQUEST');
export const getQuizCommentsSuccess = createAction('GET_QUIZ_COMMENTS_SUCCESS');
export const getQuizCommentsFail = createAction('GET_QUIZ_COMMENTS_FAIL');

export const addPaymentCommentsRequest = createAction('ADD_PAYMENT_COMMENTS_REQUEST');
export const addPaymentCommentsSuccess = createAction('ADD_PAYMENT_COMMENTS_SUCCESS');
export const addPaymentCommentsFail = createAction('ADD_PAYMENT_COMMENTS_FAIL');

export const addQuizCommentsRequest = createAction('ADD_QUIZ_COMMENTS_REQUEST');
export const addQuizCommentsSuccess = createAction('ADD_QUIZ_COMMENTS_SUCCESS');
export const addQuizCommentsFail = createAction('ADD_QUIZ_COMMENTS_FAIL');

export const restrictUserLeverageRequest = createAction('RESTRICT_USER_LEVERAGE_REQUEST');
export const restrictUserLeverageSuccess = createAction('RESTRICT_USER_LEVERAGE_SUCCESS');
export const restrictUserLeverageFail = createAction('RESTRICT_USER_LEVERAGE_FAIL');

export const createMailchimpUserRequest = createAction('CREATE_MAILCHIMP_USER_REQUEST');
export const createMailchimpUserSuccess = createAction('CREATE_MAILCHIMP_USER_SUCCESS');
export const createMailchimpUserFail = createAction('CREATE_MAILCHIMP_USER_FAIL');

export const removeUserRequest = createAction('REMOVE_USER_REQUEST');
export const removeUserSuccess = createAction('REMOVE_USER_SUCCESS');
export const removeUserFail = createAction('REMOVE_USER_FAIL');

export const openRemoveUserModal = createAction('OPEN_REMOVE_USER_MODAL');
export const closeRemoveUserModal = createAction('CLOSE_REMOVE_USER_MODAL');

export const openAccountCloseModal = createAction('OPEN_ACCOUNT_CLOSE_MODAL');
export const closeAccountCloseModal = createAction('CLOSE_ACCOUNT_CLOSE_MODAL');
export const accountCloseSuccess = createAction('ACCOUNT_CLOSE_SUCCESS');

export const openAccountRestoreModal = createAction('OPEN_ACCOUNT_RESTORE_MODAL');
export const closeAccountRestoreModal = createAction('CLOSE_ACCOUNT_RESTORE_MODAL');
export const onAccountRestoreSuccess = createAction('ACCOUNT_RESTORE_SUCCESS');

export const accountCloseRequest = createAction('ACCOUNT_CLOSE_REQUEST');
export const accountCloseFail = createAction('ACCOUNT_CLOSE_FAIL');

export const accountRestoreRequest = createAction('ACCOUNT_RESTORE_REQUEST');
export const accountRestoreFail = createAction('ACCOUNT_RESTORE_FAIL');

export const openSetTestUserModal = createAction('OPEN_SET_TEST_USER_MODAL');
export const closeSetTestUserModal = createAction('CLOSE_SET_TEST_USER_MODAL');

export const setRecentTrsVisibilityRequest = createAction('SET_RECENT_TRS_VISIBILITY_REQUEST');
export const setRecentTrsVisibilitySuccess = createAction('SET_RECENT_TRS_VISIBILITY_SUCCESS');
export const setRecentTrsVisibilityFail = createAction('SET_RECENT_TRS_VISIBILITY_FAIL');

export const emailResendRequest = createAction('EMAIL_RESEND_REQUEST');
export const emailResendSuccess = createAction('EMAIL_RESEND_SUCCESS');
export const emailResendFail = createAction('EMAIL_RESEND_FAIL');

export const getTicketsRequest = createAction('GET_TICKETS_REQUEST');
export const getTicketsSuccess = createAction('GET_TICKETS_SUCCESS');
export const getTicketsFail = createAction('GET_TICKETS_FAIL');

export const getTicketDetailsRequest = createAction('GET_TICKET_DETAILS_REQUEST');
export const getTicketDetailsSuccess = createAction('GET_TICKET_DETAILS_SUCCESS');
export const getTicketDetailsFail = createAction('GET_TICKET_DETAILS_FAIL');

export const getTicketConversationsRequest = createAction('GET_TICKET_CONVERSATIONS_REQUEST');
export const getTicketConversationsSuccess = createAction('GET_TICKET_CONVERSATIONS_SUCCESS');
export const getTicketConversationsFail = createAction('GET_TICKET_CONVERSATIONS_FAIL');

export const setUnlimitedAccountsRequest = createAction('SET_UNLIMITED_ACCOUNTS_REQUEST');
export const setUnlimitedAccountsSuccess = createAction('SET_UNLIMITED_ACCOUNTS_SUCCESS');
export const setUnlimitedAccountsFail = createAction('SET_UNLIMITED_ACCOUNTS_FAIL');

export const setUnlimitedDemoAccountsRequest = createAction('SET_UNLIMITED_DEMO_ACCOUNTS_REQUEST');
export const setUnlimitedDemoAccountsSuccess = createAction('SET_UNLIMITED_DEMO_ACCOUNTS_SUCCESS');
export const setUnlimitedDemoAccountsFail = createAction('SET_UNLIMITED_DEMO_ACCOUNTS_FAIL');

export const setDemoAccountUnlimitedExpirationRequest = createAction(
  'SET_DEMO_ACCOUNT_UNLIMITED_EXPIRATION_REQUEST',
);
export const setDemoAccountUnlimitedExpirationSuccess = createAction(
  'SET_DEMO_ACCOUNT_UNLIMITED_EXPIRATION_SUCCESS',
);
export const setDemoAccountUnlimitedExpirationFail = createAction(
  'SET_DEMO_ACCOUNT_UNLIMITED_EXPIRATION_FAIL',
);

export const setTestUserRequest = createAction('SET_TEST_USER_REQUEST');
export const setTestUserSuccess = createAction('SET_TEST_USER_SUCCESS');
export const setTestUserFail = createAction('SET_TEST_USER_FAIL');

export const setMaximumLeverageRequest = createAction('SET_MAXIMUM_LEVERAGE_REQUEST');
export const setMaximumLeverageSuccess = createAction('SET_MAXIMUM_LEVERAGE_SUCCESS');
export const setMaximumLeverageFail = createAction('SET_MAXIMUM_LEVERAGE_FAIL');

export const openMailChimpModal = createAction('OPEN_MAILCHIMP_MODAL');
export const closeMailChimpModal = createAction('CLOSE_MAILCHIMP_MODAL');

export const openSetProClientModal = createAction('OPEN_SET_PRO_CLIENT_MODAL');
export const closeSetProClientModal = createAction('CLOSE_SET_PRO_CLIENT_MODAL');

export const openSetUnlimitedDemoAccountsModal = createAction(
  'OPEN_SET_UNLIMITED_DEMO_ACCOUNTS_MODAL',
);
export const closeSetUnlimitedDemoAccountsModal = createAction(
  'CLOSE_SET_UNLIMITED_DEMO_ACCOUNTS_MODAL',
);
export const openSetUnlimitedAdditionalAccountsModal = createAction(
  'OPEN_SET_UNLIMITED_ADDITIONAL_ACCOUNTS_MODAL',
);
export const closeSetUnlimitedAdditionalAccountsModal = createAction(
  'CLOSE_SET_UNLIMITED_ADDITIONAL_ACCOUNTS_MODAL',
);

export const openSetAccountsCreationModal = createAction('OPEN_SET_ACCOUNTS_CREATION_MODAL');
export const closeSetAccountsCreationModal = createAction('CLOSE_SET_ACCOUNTS_CREATION_MODAL');

export const openSetWholesaleStatusModal = createAction('OPEN_SET_WHOLESALE_STATUS_MODAL');
export const closeSetWholesaleStatusModal = createAction('CLOSE_SET_WHOLESALE_STATUS_MODAL');

export const openSetLeverageChangeModal = createAction('OPEN_SET_LEVERAGE_CHANGE_MODAL');
export const closeSetLeverageChangeModal = createAction('CLOSE_SET_LEVERAGE_CHANGE_MODAL');

export const openSetMaximumLeverageModal = createAction('OPEN_SET_MAXIMUM_LEVERAGE_MODAL');
export const closeSetMaximumLeverageModal = createAction('CLOSE_SET_MAXIMUM_LEVERAGE_MODAL');

export const openResendEmailModal = createAction('OPEN_RESEND_EMAIL_MODAL');
export const closeResendEmailModal = createAction('CLOSE_RESEND_EMAIL_MODAL');

export const setEcpUserRequest = createAction('SET_ECP_USER_REQUEST');
export const setEcpUserSuccess = createAction('SET_ECP_USER_SUCCESS');
export const setEcpUserFail = createAction('SET_ECP_USER_FAIL');

export const getAccountsWithOpenedTradesRequest = createAction(
  'GET_ACCOUNTS_WITH_OPENED_TRADES_REQUEST',
);
export const getAccountsWithOpenedTradesSuccess = createAction(
  'GET_ACCOUNTS_WITH_OPENED_TRADES_SUCCESS',
);
export const getAccountsWithOpenedTradesFail = createAction('GET_ACCOUNTS_WITH_OPENED_TRADES_FAIL');

export const editTradingStatusRequest = createAction('EDIT_TRADING_STATUS_REQUEST');
export const editTradingStatusSuccess = createAction('EDIT_TRADING_STATUS_SUCCESS');
export const editTradingStatusFail = createAction('EDIT_TRADING_STATUS_FAIL');

export const setAccountCreationRequest = createAction('SET_ACCOUNT_CREATION_REQUEST');
export const setAccountCreationSuccess = createAction('SET_ACCOUNT_CREATION_SUCCESS');
export const setAccountCreationFail = createAction('SET_ACCOUNT_CREATION_FAIL');

export const searchQuizAnswersRequest = createAction('SEARCH_QUIZ_ANSWERS_REQUEST');
export const searchQuizAnswersSuccess = createAction('SEARCH_QUIZ_ANSWERS_SUCCESS');
export const searchQuizAnswersFail = createAction('SEARCH_QUIZ_ANSWERS_FAIL');

export const enableEmailVerificationRequest = createAction('ENABLE_EMAIL_VERIFICATION_REQUEST');
export const enableEmailVerificationSuccess = createAction('ENABLE_EMAIL_VERIFICATION_SUCCESS');
export const enableEmailVerificationFail = createAction('ENABLE_EMAIL_VERIFICATION_FAIL');
