import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { DATE } from '../../config';

const TPDatePickerMask = ({ inputRef, mask = DATE.dateMask, ...props }) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
    />
  );
};

TPDatePickerMask.propTypes = {
  mask: PropTypes.array,
};

export default React.forwardRef((props, ref) => <TPDatePickerMask {...props} inputRef={ref} />);
