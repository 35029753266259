import { createAction } from 'redux-actions';

export const getPendingVerificationsRequest = createAction('GET_PENDING_VERIFICATIONS_REQUEST');
export const getPendingVerificationsSuccess = createAction('GET_PENDING_VERIFICATIONS_SUCCESS');
export const getPendingVerificationsFail = createAction('GET_PENDING_VERIFICATIONS_FAIL');

export const getPendingAccountsRequest = createAction('GET_PENDING_ACCOUNTS_REQUEST');
export const getPendingAccountsSuccess = createAction('GET_PENDING_ACCOUNTS_SUCCESS');
export const getPendingAccountsFail = createAction('GET_PENDING_ACCOUNTS_FAIL');

export const approveAccountsRequest = createAction('APPROVE_ACCOUNTS_REQUEST');
export const approveAccountsSuccess = createAction('APPROVE_ACCOUNTS_SUCCESS');
export const approveAccountsFail = createAction('APPROVE_ACCOUNTS_FAIL');

export const declineAccountsRequest = createAction('DECLINE_ACCOUNTS_REQUEST');
export const declineAccountsSuccess = createAction('DECLINE_ACCOUNTS_SUCCESS');
export const declineAccountsFail = createAction('DECLINE_ACCOUNTS_FAIL');

export const getPendingExperienceUploadsRequest = createAction(
  'GET_PENDING_EXPERIENCE_UPLOADS_REQUEST',
);
export const getPendingExperienceUploadsSuccess = createAction(
  'GET_PENDING_EXPERIENCE_UPLOADS_SUCCESS',
);
export const getPendingExperienceUploadsFail = createAction('GET_PENDING_EXPERIENCE_UPLOADS_FAIL');

export const removeTradingStatementEvidenceRequest = createAction(
  'REMOVE_TRADING_STATEMENT_EVIDENCE_REQUEST',
);
export const removeTradingStatementEvidenceSuccess = createAction(
  'REMOVE_TRADING_STATEMENT_EVIDENCE_SUCCESS',
);
export const removeTradingStatementEvidenceFail = createAction(
  'REMOVE_TRADING_STATEMENT_EVIDENCE_FAIL',
);
export const getKYCPendingRequest = createAction('GET_K_Y_C_PENDING_REQUEST');
export const getKYCPendingSuccess = createAction('GET_K_Y_C_PENDING_SUCCESS');
export const getKYCPendingFail = createAction('GET_K_Y_C_PENDING_FAIL');

export const getKYCPendingFollowUpRequest = createAction('GET_K_Y_C_PENDING_FOLLOW_UP_REQUEST');
export const getKYCPendingFollowUpSuccess = createAction('GET_K_Y_C_PENDING_FOLLOW_UP_SUCCESS');
export const getKYCPendingFollowUpFail = createAction('GET_K_Y_C_PENDING_FOLLOW_UP_FAIL');

export const setKYCPendingRequest = createAction('SET_K_Y_C_PENDING_REQUEST');
export const setKYCPendingSuccess = createAction('SET_K_Y_C_PENDING_SUCCESS');
export const setKYCPendingFail = createAction('SET_K_Y_C_PENDING_FAIL');
