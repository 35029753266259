export const styles = {
  dateContainer: (theme) => theme.datePickerTable.dateContainer,
  dateTextField: (theme) => theme.datePickerTable.dateTextField,
};

export default () => ({
  root: {
    padding: 10,
    zIndex: 2,
  },
  button: {
    height: 32,
    marginLeft: 15,
  },
  settingsIcon: {
    color: '#c2c2c5',
    cursor: 'pointer',
  },
  customSelector: {
    marginRight: 15,
  },
  statsCustomization: {},
  additionalFilter: {
    marginLeft: 20,
  },
  icon: {
    color: '#c2c2c5',
    cursor: 'pointer',
  },
  textBetween: {
    paddingTop: 8,
  },
});
