import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { replace } from 'modules/reduxNavigation/actions';
import { Routes } from 'constants/routeConstants';
import {
  sendVerificationEmailFail,
  sendVerificationEmailSuccess,
  verifyEmailSuccess,
  verifyEmailFail,
} from '../actions';
import * as notificationsActions from '../../../../notifications/actions';
import showErrorMessage from 'utils/showErrorMessage';
import { redirectToDashboard } from '../../SignIn/sagas';
import { getUser } from '../../../selectors';

function* redirectUser() {
  const user = yield select(getUser);
  if (user.mfa?.enabled || user.mfa?.postponed) {
    yield call(redirectToDashboard);
  } else {
    yield put(replace(Routes.AUTH_MFA_SETUP));
  }
}

function* handleVerifyEmailSuccess() {
  yield call(redirectUser);
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'auth:emailVerification.notifications.emailVerified',
    }),
  );
}

function* handleVerifyEmailFail(action) {
  if (action?.error?.data?.index === '558') {
    yield call(redirectUser);
  }
}

function* handleFail(action) {
  yield call(showErrorMessage, action);
}

function* handleSendVerificationEmailSuccess() {
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'auth:emailVerification.notifications.verificationEmailSent',
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(verifyEmailSuccess, handleVerifyEmailSuccess),
    takeEvery(verifyEmailFail, handleVerifyEmailFail),
    takeEvery(sendVerificationEmailSuccess, handleSendVerificationEmailSuccess),
    takeEvery(sendVerificationEmailFail, handleFail),
  ]);
}
