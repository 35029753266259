const getScrollableParent = (node) => {
  if (!window.getComputedStyle || node == null || node === document) {
    return null;
  }
  const overflow = window.getComputedStyle(node).overflow;
  if (overflow === 'auto' || overflow === 'scroll') {
    return node;
  } else {
    return getScrollableParent(node.parentNode);
  }
};

const isElInViewport = (el) => {
  if (!el || !el.getBoundingClientRect) return false;

  const rect = el.getBoundingClientRect();
  const scrollableContainer = getScrollableParent(el) || document.body;
  const containerRect = scrollableContainer.getBoundingClientRect();
  return rect.top >= containerRect.top && containerRect.bottom >= rect.bottom;
};

export default isElInViewport;
