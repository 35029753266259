import { createPendingSelector } from 'modules/api/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateMfaRequest } from '../actions';
import MfaInput from '../components/MfaInput';
import { MFA_INPUT_FORM } from '../constants';

const MfaInputContainer = () => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(validateMfaRequest));

  const handleSubmit = useCallback(
    (data) => {
      dispatch(validateMfaRequest(data));
    },
    [dispatch],
  );

  return <MfaInput form={MFA_INPUT_FORM} isPending={isPending} onSubmit={handleSubmit} />;
};

export default MfaInputContainer;
