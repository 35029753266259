import { handleActions } from 'redux-actions';
import {
  verifyEmailFail,
  verifyEmailSuccess,
  sendVerificationEmailFail,
  sendVerificationEmailSuccess,
} from '../actions';

const defaultState = {
  verificationError: null,
  canResend: false,
};

export default handleActions(
  {
    [verifyEmailSuccess]: (state) => {
      return {
        ...state,
        verificationError: null,
        canResend: false,
      };
    },
    [verifyEmailFail]: (state, action) => {
      const errorData = action?.error?.data;
      return {
        ...state,
        verificationError: errorData?.message || {
          key: 'backend:somethingWrongContactHelp',
        },
        canResend: !errorData || errorData?.index !== '558',
      };
    },
    [sendVerificationEmailSuccess]: (state) => {
      return {
        ...state,
        verificationError: null,
      };
    },
    [sendVerificationEmailFail]: (state, action) => {
      return {
        ...state,
        verificationError: action?.error?.data?.message || {
          key: 'backend:somethingWrongContactHelp',
        },
      };
    },
  },
  defaultState,
);
