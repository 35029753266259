import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import Layout from '../../../../components/LayoutNew';
import DocumentTitle from 'react-document-title';
import styles from './styles';
import CustomLink from '../../../../../common/CustomLink';
import { getStaticSiteUrl } from '../../../../../../utils/getStaticSiteUrl';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ErrorOutline } from '@material-ui/icons';
import { getTranslation } from '../../../../../../utils/compositeTranslationHandler';
import { setMetaDataDescription } from '../../../../../../utils/setMetaDataDescription';
import Button from '../../../../../common/PrimaryButton';
import { Routes as ROUTES } from '../../../../../../constants/routeConstants';

const SYMBOLS_IN_ROW = 62;

const EmailVerification = ({
  classes,
  token,
  onSendEmail,
  verificationError = null,
  canResend,
  isPending,
}) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL, LOGO, SIGNIN_DISCLAIMER } = config;

  const errorRows = useMemo(() => {
    if (!verificationError) return 1;
    return Math.ceil(getTranslation(t, verificationError).length / SYMBOLS_IN_ROW);
  }, [verificationError, t]);

  React.useEffect(() => {
    setMetaDataDescription(
      t('emailVerification.documentDescription', { company: CURRENT_HUB_LABEL }),
    );
  }, [CURRENT_HUB_LABEL, t]);

  const error = !token ? { key: 'backend:verificationTokenExpired' } : verificationError;

  return (
    <Layout className={classes.spaceForFooter}>
      <div className={classNames({ [classes.mainLayout]: SIGNIN_DISCLAIMER })}>
        <DocumentTitle
          title={t('emailVerification.documentTitle', { company: CURRENT_HUB_LABEL })}
        />
        <div className={classes.formWrapper}>
          <CustomLink className={classes.logoWrapper} to={getStaticSiteUrl()}>
            <img src={LOGO.blue} className={classes.logo} alt="black-logo" />
          </CustomLink>
          <div className={classes.form}>
            <div className={classes.header}>{t('emailVerification.formTitle')}</div>
            <section className={classes.wrapper} style={{ paddingBottom: 16 * errorRows }}>
              {error && !isPending && (
                <span className={classes.formError}>
                  <ErrorOutline className={classes.errorIcon} />
                  {getTranslation(t, error)}
                </span>
              )}
            </section>

            {canResend ? (
              <Button
                fullWidth
                color="primary"
                size="large"
                label={t('emailVerification.resendEmail')}
                type="submit"
                onClick={onSendEmail}
                className={classNames(classes.submitButton)}
              />
            ) : null}
            <section className={classes.customLinkWrapper}>
              <CustomLink className={classes.backtoLogin} to={ROUTES.SIGNIN}>
                {t('backToLogIn')}
              </CustomLink>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};
//
// EmailVerification.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
// };

export default withStyles(styles)(EmailVerification);
