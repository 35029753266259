import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from 'material-latest/ListItemIcon';
import Box from 'material-latest/Box';
import MenuItem from 'material-latest/MenuItem';

import TPLink from 'components/TP-UI/TPLink';
import TPTypography from 'components/TP-UI/TPTypography';
import { getClassNames } from 'components/TP-UI/helpers/util';

import styles from './styles';

const TPNavMenuListItem = ({
  id,
  className,
  label,
  iconComponent: IconComponent,
  path = '',
  selected = false,
  minimized = false,
  onClick,
}) => {
  const contentWrapperClassName = [
    styles.contentWrapper,
    // selected && styles.selected,
    ...getClassNames(className),
  ];

  return (
    <MenuItem id={id} key={id} selected={selected} onClick={onClick} sx={[styles.root]}>
      {minimized || !path ? (
        <Box sx={contentWrapperClassName}>
          <ListItemIcon sx={[styles.iconContainer, minimized && styles.fullWidth]}>
            {typeof IconComponent === 'string' ? (
              <img src={IconComponent} alt={label} />
            ) : (
              <IconComponent />
            )}
          </ListItemIcon>
          {!minimized ? (
            <TPTypography className={styles.text} bold={selected}>
              {label}
            </TPTypography>
          ) : null}
        </Box>
      ) : (
        <TPLink path={path} className={contentWrapperClassName}>
          <ListItemIcon sx={styles.iconContainer}>
            {typeof IconComponent === 'string' ? (
              <img src={IconComponent} alt={label} />
            ) : (
              <IconComponent />
            )}
          </ListItemIcon>
          <TPTypography component="span" className={styles.text} bold={selected}>
            {label}
          </TPTypography>
        </TPLink>
      )}
    </MenuItem>
  );
};

TPNavMenuListItem.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  iconComponent: PropTypes.elementType.isRequired,
  path: PropTypes.string,
  selected: PropTypes.bool,
  minimized: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TPNavMenuListItem;
