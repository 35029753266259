import createAction from 'redux-actions/es/createAction';

export const auth = createAction('AUTHENTICATE');
export const authenticationFail = createAction('AUTHENTICATION_FAIL');
export const cleanupAuth = createAction('CLEANUP_AUTH');
export const logout = createAction('LOG_OUT');
export const refreshToken = createAction('REFRESH_TOKEN');
export const logoutAsCurrentUser = createAction('LOG_OUT_AS_CURRENT_USER');

export const saveReferral = createAction('SAVE_REFERRAL');

export const firstVisitFulfilled = createAction('FIRST_VISIT_FULFILLED');

export const loginSinceRejectionRequest = createAction('LOGIN_SINCE_REJECTION_REQUEST');
export const loginSinceRejectionSuccess = createAction('LOGIN_SINCE_REJECTION_SUCCESS');
export const loginSinceRejectionFail = createAction('LOGIN_SINCE_REJECTION_FAIL');

export const setCxdForUserRequest = createAction('SET_CXD_FOR_USER_REQUEST');
export const setCxdForUserSuccess = createAction('SET_CXD_FOR_USER_SUCCESS');
export const setCxdForUserFail = createAction('SET_CXD_FOR_USER_FAIL');

export const updateUserPhoneNumber = createAction('UPDATE_USER_PHONE_NUMBER');
